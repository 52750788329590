import { SheetsRegistry } from 'jss';
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import {scale} from "./utils";
import {STYLES_CONSTS} from "./variables";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: purple[300],
      main: purple[500],
      dark: purple[700],
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
  pageHeading: {
    fontSize: scale(STYLES_CONSTS.HEADING_FONT_SIZE),
    color: STYLES_CONSTS.HEADING_COLOR,
    fontFamily: 'Open Sans Condensed',
    fontWeight: 300,
    letterSpacing: 0,
    minHeight: scale(24),
    marginBottom: scale(29)
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: 19,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 36,
    maxWidth: 370
  },
  blueButton: {
    backgroundColor: STYLES_CONSTS.BLUE_BUTTON,
    borderWidth: 1,
    borderRadius: 11,
    borderColor: STYLES_CONSTS.BLUE_BUTTON,
    fontSize: scale(18),
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'none',
    letterSpacing: 0.11,
    color: STYLES_CONSTS.WHITE_COLOR,
    minHeight: scale(53),
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: STYLES_CONSTS.WHITE_COLOR,
      color: STYLES_CONSTS.BLUE_BUTTON,
      borderColor: STYLES_CONSTS.BLUE_BUTTON,
    },
    '&:disabled': {
      backgroundColor: STYLES_CONSTS.DISABLED_COLOR,
      color: STYLES_CONSTS.WHITE_COLOR
    },
  },
  blueButtonLabel: {
    fontSize: scale(18),
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'none',
    letterSpacing: 0.11,
    color: STYLES_CONSTS.WHITE_COLOR,
    '&:hover': {
      color: STYLES_CONSTS.BLUE_BUTTON,
    }
  },
  greenButton: {
    backgroundColor: STYLES_CONSTS.WHITE_COLOR,
    borderColor: STYLES_CONSTS.GREEN_BUTTON,
    fontSize: scale(16),
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: 'none',
    letterSpacing: 0.11,
    color: STYLES_CONSTS.GREEN_BUTTON,
    minHeight: scale(53),
    marginTop: scale(18),
    width: '100%',
    '&:hover': {
      backgroundColor: STYLES_CONSTS.GREEN_BUTTON,
      color: STYLES_CONSTS.WHITE_COLOR,
    }
  },
  buttonRadius: 11

});

function createPageContext() {
  return {
    theme,
    sheetsManager: new Map(),
    sheetsRegistry: new SheetsRegistry(),
    generateClassName: createGenerateClassName(),
  };
}

let pageContext;

export default function getPageContext() {
  if (!process.browser) {
    return createPageContext();
  }
  if (!pageContext) {
    pageContext = createPageContext();
  }

  return pageContext;
}
