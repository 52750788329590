/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
//project code
import { BackArrowIcon, PrintIconTransparent } from "./share/ReplayIcon";
import { STYLES_CONSTS } from './share/variables';
import { scale } from './share/utils';
import { GeneralRecomendations, RedFlags, SpecialRecommendation } from './share/Recommendations';

const styles = theme => ({
    root: {
        backgroundColor: STYLES_CONSTS.DISABLED_COLOR,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: 0,
        height: '100%',
        overflow: 'auto'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
        marginTop: 19,
        marginLeft: scale(16),
        marginRight: scale(16),
        marginBottom: 16,
        width: 750,
        paddingRight: 5,
        paddingLeft: 5,
        overflow: 'scroll',
    },
    printArea: {
        backgroundColor: STYLES_CONSTS.WHITE_COLOR,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 1,
        marginTop: 50,
        marginBottom: 16,
        marginLeft: 20,
        marginRight: 20,
        height: '100%',
        overflow: 'scroll !important',
    },
    disclaimer: {
        marginTop: 31,
        fontSize: 11,
        color: STYLES_CONSTS.DISCLAIMER_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontStyle: 'italic',
        letterSpacing: 0,
        minHeight: 30
    },
    heading: {
        fontSize: 21,
        color: STYLES_CONSTS.HEADING_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 300,
        letterSpacing: 0,
        minHeight: 24,
        marginBottom: 0
    },
    prompt: {
        marginTop: 8,
        display: 'inline-block',
        fontSize: 15,
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        letterSpacing: 0,
        minHeight: 14,
        textTransform: 'none'
    },
    promptBold: {
        display: 'inline-block',
        fontSize: 15,
        color: STYLES_CONSTS.HEADING_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        minHeight: 14,
        textTransform: 'none'
    },
    top: {
        width: 300,
        textAlign: 'center'
    },
    score: {
        display: 'inline-block',
        fontSize: 46,
        color: STYLES_CONSTS.HEADING_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 800,
        letterSpacing: 0,
        minHeight: 62,
        textTransform: 'none'
    },
    noScore: {
        display: 'inline-block',
        fontSize: 23,
        color: STYLES_CONSTS.HEADING_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 800,
        letterSpacing: 0,
        minHeight: 62,
        textTransform: 'none'
    },
    scoreText: {
        display: 'inline-block',
        fontSize: 15,
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        letterSpacing: 0,
        minHeight: 62,
        textTransform: 'none',
        marginTop: 4
    },
    buttonsContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: 26
    },
    question: {
        marginBottom: 2,
        fontWeight: 300,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.HEADING_COLOR,
    },
    answer: {
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.HEADING_COLOR,
    },
    qaPair: {
        marginBottom: 16,
        textAlign: 'left'
    },
    iconSmall: {
        position: 'absolute',
        left: 0,
        fontSize: 20,
        width: 32,
        height: 32,
        marginRight: 30,
        marginLeft: 31
    },
    inaccurate: {
        marginTop: scale(5),
        marginBottom: scale(5),
        backgroundColor: STYLES_CONSTS.DISABLED_COLOR,
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'solid',
        borderColor: STYLES_CONSTS.BLUE_BUTTON
    },
    inaccurateText: {
        fontSize: scale(14),
        lineHeight: scale(14) + 'px'
    },
    printIcon: {
        left: 0,
        fontSize: 20,
        width: 22,
        height: 22,
        marginRight: 6,
        color: STYLES_CONSTS.WHITE_COLOR,
    },
    backArrowIcon: {
        left: 0,
        fontSize: 20,
        width: 22,
        height: 22,
        marginRight: 6,
        color: STYLES_CONSTS.WHITE_COLOR,
    },
    printButton: {
        backgroundColor: 'transparent',
        minHeight: 30
    },
    printButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: 240,
        fontSize: 15,
        minHeight: 20,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.WHITE_COLOR,
    },
    printControls: {
        boxShadow: "0px 3px 4px -1px rgba(0,0,0,0.3)",
        opacity: 0.8,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 50,
        backgroundColor: STYLES_CONSTS.BLUE_BUTTON,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    recommendations: {
        textAlign: 'left',
        fontSize: 15,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        '&>p:first-child': {
            fontSize: 18,
            fontWeight: 400,
            fontFamily: 'Open Sans',
        },
    },
    printLink: {
      textDecoration: 'none'
    },

    generalRecs: {
        fontSize: 15,
        fontWeight: 200,
        fontFamily: 'Open Sans',
        'a': {
            textDecoration: 'none'
        }
    }

});

class Report extends React.Component {

    stPdf = () => {
        window.html2canvas = html2canvas;

        const pdf = new jsPDF('p', 'pt', 'a4'); // For A4 Sheet layout
        pdf.html(document.getElementById('divIdToPrint'), {
            retina: true,
            pagesplit: true,
            margin: [20, 20],
            useFor: 'page', // This property is mandatory to keep the margin to supsequent pages

            callback: function(pdf) {
                pdf.save('test.pdf');
            }

        });
    };

    pdfSimple = () => {
        window.html2canvas = html2canvas;

        const pdf = new jsPDF(
            {
                orientation: 'p',
                unit: 'pt',
                format: 'letter',
                putOnlyUsedFonts: true,
                compress: true,
            });
        pdf.html(document.getElementById('divIdToPrint'), {
            callback: function (pdf) {
                pdf.save('a-pdf')
            },
            html2canvas: {
                scale: 0.6,
                windowWidth: 800
            },
            pagesplit: true,
            margin: [50, 50, 50, 50],
            useFor: 'page' // This property is mandatory to keep the margin to supsequent pages
        });
    };

    async getCanvas() {
        const item = document.getElementById('printResult');
        const item1 = document.getElementById('printGeneral');
        const item2 = document.getElementById('printRedFlag');
        const item3 = document.getElementById('printSpecial');
        const options = {scale: 1, backgroundColor: null, windowWidth: 750};
        const k = .5;
        const margin = 20;
        const pageHeight = 600;
        let offset = 0;
        let canvas = await html2canvas(item, options);
        let canvas1 = await html2canvas(item1, options);
        let canvas2 = await html2canvas(item2, options);
        let canvas3 = await html2canvas(item3, options);
        const canvasArr = [canvas, canvas1, canvas2, canvas3];
        const pdf = new jsPDF('p', 'px', 'a4', true);
        let pages = 1;
        const disclaimerText = 'Recommendations provided are a broad guide based on current available evidence.' +
            'This App may augment good clinical care and is not meant to replace a clinical' +
            'diagnosis, assessment or treatment plan.';
        const splitText = pdf.splitTextToSize(disclaimerText, 500);
        canvasArr.forEach( (el, i) => {
            if (i < canvasArr.length - 1 && (el.height*k + canvasArr[i+1].height*k + margin*4) > pageHeight) {
                pages += 1;
            }
        });

        canvasArr.forEach( (canvas, i) => {
            let pageData = canvas.toDataURL('image/png', 1.0);
            pdf.setFontSize(10);
            if (offset === 0 ) {
                pdf.setFont('helvetica');
                pdf.text('Page #' + (i + 1) + ' of ' + pages, 20, 10 + margin);
                pdf.text('Schizophrenia Risk Estimator', 100, 10 + margin);
                pdf.text('The University of Manitoba Department of Psychiatry', 210, 10 + margin);
            }
            pdf.addImage(pageData, 'PNG', i === 0 ? 120 : 40, i === 0 ? 160 : margin*2 + offset, canvas.width*k, canvas.height*k, undefined, 'MEDIUM');
            if (i === 0) {
                //pdf.addImage(logo, 'JPEG', 155, 30, 106, 98, undefined, 'MEDIUM');
                pdf.setFontSize(18);
                pdf.text('Schizophrenia Risk Estimator Results', 100, 100);
                pdf.setFontSize(12);
                pdf.text('View following pages to receive General, Specific recommendations, and RED FLAGS information', 20, canvas.height*k + margin*4 + 160);
            }
            if (i < canvasArr.length - 1) {
                if ((canvas.height*k + canvasArr[i+1].height*k + margin*6) > pageHeight) {
                    pdf.addPage();
                    offset = 0;
                } else {
                    offset = canvas.height*k + margin;
                }
            } else {
                if ( offset === 0 ) {
                    pdf.text(splitText, 20, canvas.height * k + margin * 5);

                } else {
                    pdf.setFont('helvetica');
                    pdf.text(splitText, 20, canvas.height * k + margin * 5 + canvasArr[i-1].height*k);

                }
            }
        });
        pdf.save('src-res.pdf');
    };

    printPDF = () => {

        const canvasArr = [];


        //html2canvas(item, {scale: 1, backgroundColor: null, windowWidth: 750}).then(canvas => {
        const pdf = new jsPDF('p', 'px', 'a4', true);
        canvasArr.forEach( canvas => {


            let contentWidth = canvas.width;
            let contentHeight = canvas.height;
            // The height of the canvas which one pdf page can show;
            let pageHeight = 841.89;
            // the height of canvas that haven't render to pdf
            let leftHeight = contentHeight;
            // addImage y-axial offset
            let position = 0;
            // a4 format [595.28,841.89]
            let imgWidth = contentWidth*.6;
            let imgHeight = contentHeight*.6;
            let pageData = canvas.toDataURL('image/png', 1.0);

            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'MEDIUM');
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'PNG', 40, position, imgWidth, imgHeight, undefined, 'MEDIUM');
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    //avoid blank page
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }
        //})
        })
        pdf.save('src-res.pdf');

    };

    pdfHtml = () => {
        window.html2canvas = html2canvas;
        const item = document.getElementById('divIdToPrint');
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: 'a4',
            putOnlyUsedFonts: false,
            compress: true,
            precision: 2.5,
        });
        pdf.html(item, {
            html2canvas: {
                scale: 0.5,
                width: 200
            },
            margin: [20, 20, 20, 20],
            callback: function (doc) {
                doc.save('src-res.pdf');
            },
        })
    };

    render() {
        const { classes, probability, report, history, assets, inaccurate } = this.props;
        if (!probability) {
            history.push('/');
        }

        if (!assets) {
            return null;
        }
        return (
            <div id="scrollId" className={classes.root}>
                <div className={classes.printControls}>
                    <Button
                        classes={{
                            root: classes.printButton,
                            label: classes.printButtonLabel
                        }}
                        onClick={() => history.goBack()}
                    > <BackArrowIcon
                        className={classes.backArrowIcon}
                        viewBox="0 0 512 512"
                    /> {assets.report.goBackButtonCaption} </Button>
                    <Button
                        classes={{
                            root: classes.printButton,
                            label: classes.printButtonLabel
                        }}
                        onClick={this.getCanvas}
                    > <PrintIconTransparent
                        className={classes.printIcon}
                        viewBox="0 0 512 512"
                    /> {assets.report.printToPdfButtonCaption} </Button>
                    </div>
                    <div className={classes.printArea}>
                        <div id="divIdToPrint" className={classes.container}>
                            <div id="printResult" className={classes.top}>
                                <Typography variant="h5" className={classes.heading}>
                                {assets.summary.heading}
                                </Typography>
                                <Typography variant="h5" className={classes.prompt}>
                                    {assets.summary.prompt}
                                </Typography>
                                <div className={classes.answers}>
                                    <Typography className={probability === null ? classes.noScore : classes.score}>
                                        {probability === null ? `${assets.summary.noScoreText}` :
                                            probability + `${assets.summary.scoreSign}`}
                                    </Typography>

                                    <Typography className={classes.scoreText}>
                                        {assets.summary.scoreText}
                                    </Typography>
                                    {inaccurate &&
                                    <div className={classes.inaccurate}>
                                        <Typography variant="body1" className={classes.inaccurateText}>
                                            {assets.summary.inaccurate}
                                        </Typography>
                                    </div>
                                    }
                                <div className={classes.buttonsContainer}>
                                {report && Object.keys(report).map((el, i) =>
                                    <div key={i} className={classes.qaPair}>
                                        <div className={classes.question}>
                                            {report[`${el}`].printText}
                                        </div>
                                        <div className={classes.answer}>
                                            {report[`${el}`].text}
                                        </div>
                                    </div>
                                )}
                                </div>
                                </div>
                            </div>
                                <div id="printGeneral" className={classes.recommendations}>
                                    <Typography variant="body1" className={classes.recHeading}>
                                        {assets.resources.generalRecsHeading}
                                    </Typography>
                                    <GeneralRecomendations styles={classes.generalRecs} />
                                </div>
                                <div id="printRedFlag" className={classes.recommendations}>
                                    <Typography variant="body1" className={classes.redFlagsHeading}>
                                        {assets.resources.redFlagsHeading}
                                    </Typography>
                                    <RedFlags styles={classes.generalRecs} />
                                </div>
                                <div id="printSpecial" className={classes.recommendations}>
                                    <Typography variant="body1" className={classes.specRecs}>
                                        {assets.resources.specificRecommendationsHeading}
                                    </Typography>
                                    <SpecialRecommendation probability={probability}/>
                                    <Typography variant="body1" className={classes.disclaimer}>
                                        {assets.intro.disclaimerCaptionText}
                                    </Typography>
                                </div>
                            </div>
                        </div>

            </div>
        );
    }
}

Report.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { probability, report, assets, inaccurate, recommendations } = state.appState;
    return { probability, report, assets, inaccurate, recommendations }
};


export default connect(mapStateToProps)(withStyles(styles)(Report));
