/* eslint-disable no-script-url */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    link: {
        margin: theme.spacing.unit,
    },
});

const ExternalLinkComponent = (props) => {
    const { classes, linkURL, text } = props;

    return (
        <Link
            href={linkURL}
            className={classes.link}
            underline='none'
            color='primary'
            target='_blank'
            rel='noopener noreferrer'
        >
            {text}
        </Link>
    );
};

ExternalLinkComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const AppLinkComponent = (props) => {
    const { classes, linkURL, text } = props;

    return (
        <Link
            to={linkURL}
            component={RouterLink}
            className={classes.link}
            underline='none'
            color='secondary'
        >
            {text}
        </Link>
    );
};

AppLinkComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const ExternalLink = withStyles(styles)(ExternalLinkComponent);
const AppLink = withStyles(styles)(AppLinkComponent);

export {
    ExternalLink,
    AppLink
}
