import React from 'react';
import SignIn from './Auth/SignIn';

import  { FirebaseContext } from './Firebase';

const Login = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <SignIn {...props} firebase={firebase}/>
            </div>)
        }
    </FirebaseContext.Consumer>
);

export default Login;