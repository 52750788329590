export const recommendations = [
    {
        generalRecs: [
            {
                category: "general",
                href: "",
                recommendationId: "-LaGTNnPZbEHrXJgi-VZ",
                text: "Just text",
                type: "text"
            }
        ],
        hasOverlap: false,
        id: "-LaHLgeY-ISI6z4s39nY",
        isActive: true,
        maxProb: 30.4,
        minProb: 30.3,
        redFlags: [
            {
                category: "redflag",
                href: "-",
                recommendationId: "-LaGT3CL-pm7HYJReGsB",
                text: "New Test Content",
                type: "text"
            }
        ],
        specialRecs: [
            {
                category: "special",
                href: "https://test.com",
                recommendationId: "-La2IJZUV8_QoOsJIx9A",
                text: "Test New text link",
                type: "link"
            }
        ]
    },
    {
        generalRecs: [
            {
                category: "general",
                href: "",
                recommendationId: "-LaGTNnPZbEHrXJgi-VZ",
                text: "Just text",
                type: "text"
            }
        ],
        hasOverlap: false,
        id: "-LaHUAqWqsC4dtx3B_IU",
        isActive: true,
        maxProb: 8,
        minProb: 4
    },
    {
        generalRecs: [
            {
                category: "general",
                href: "",
                recommendationId: "-LaHiSr6IR69nkLpOQj0",
                text: "General Recommendation 1",
                type: "text"
            }
        ],
        hasOverlap: false,
        id: "-LaM7YdzpYzfth9B-Rtl",
        isActive: true,
        maxProb: 34.6,
        minProb: 34.6,
        redFlags: [
            {
                category: "redflag",
                href: "",
                recommendationId: "-LaHiYOVeMV39HCxomzx",
                text: "Red Flag 1",
                type: "text"
            }
        ],
        specialRecs: [
            {
                category: "special",
                href: "",
                recommendationId: "-LaHidA6q09FdAB08SFv",
                text: "Special Recommendation 1",
                type: "text"
            }
        ]
    }
];