import app from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';

const firebaseConfigSets = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const restoreAssetFromId = (id, val) => {
    const props = id.replace('.', '/');
    const obj = {};
    obj[props] = val;
    return obj
}

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfigSets);
        this.auth = app.auth();
        this.database = app.database();

    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
            .then(() =>
                this.auth.signInWithEmailAndPassword(email, password))
            .catch();

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    writeNewTree = (userID, treeName, description) => {
        return new Promise((resolve, reject) => {
            let newKey = this.database.ref(`decision_tree/${userID}/`).push().key;
            let result = this.database.ref(`decision_tree/${userID}/${newKey}/`).set({
                treeId: newKey,
                treeName: treeName,
                treeDescription: description,
                questions: []
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    updateAsset = (userID, assetId, assetVal) => {
        const asset = restoreAssetFromId(assetId, assetVal);
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`assets/${userID}/`).update(asset, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    setBulkAssets  = (userID, bulkAssets) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`assets/${userID}/`).set(bulkAssets, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    deployAssets = (userID, activeAssets) => {
        return new Promise((resolve, reject) => {
           let result = this.database.ref(`active_assets/`).set(activeAssets, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            })
        });
    };

    updateTree = (userID, treeId, treeName, description, questions, initial) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`decision_tree/${userID}/${treeId}/`).set({
                treeId: treeId,
                treeName: treeName,
                treeDescription: description,
                initialQuestion: initial,
                questions: questions ? questions : []
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    updateQuestion = (userID, questionId, text, printText, footNote, answers) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`questions/${userID}/${questionId}/`).set({
                questionId: questionId,
                questionText: text,
                printText: printText,
                footNote: footNote,
                answers: answers ? answers : []
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    updateRecommendation = (userID, recommendationId, category, type, text, href) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`recommendations/${userID}/${recommendationId}/`).set({
                recommendationId: recommendationId,
                category: category,
                type: type,
                text: text,
                href: href,
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    updateRecommendationsBundle = (userID, bundleId, minProb, maxProb, generalRecs, redFlags, specialRecs, isActive) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`recBundles/${userID}/${bundleId}/`).set({
                bundleId: bundleId,
                minProb: minProb,
                maxProb: maxProb,
                generalRecs: generalRecs ? generalRecs : [],
                redFlags: redFlags ? redFlags : [],
                specialRecs: specialRecs ? specialRecs : [],
                isActive: isActive,
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    setTreeActive = (updates, userID, activeTree) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref().update(updates, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(new Promise((resolve, reject) => {
                        result = this.database.ref(`active_tree/`).set(activeTree, error => {
                            if (error) {
                                reject(error);
                            } else {
                                resolve(result);
                            }
                        })}));

                }
            });
        })
    };

    deployRecommendations = (userID, recommendations) => {
        return new Promise((resolve, reject) => {
            let result = this.database.ref(`active_recs/`).set(recommendations, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        });
    };

    deleteTree = (userID, treeId) => {
        return new Promise((resolve, reject) => {
            const result = this.database.ref(`decision_tree/${userID}/${treeId}/`).set(null, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    writeNewQuestion = (userID, questionText, printText, footNote, answers) => {
        return new Promise((resolve, reject) => {
            let newKey = this.database.ref(`questions/${userID}/`).push().key;
            let result = this.database.ref(`questions/${userID}/${newKey}/`).set({
                questionId: newKey,
                questionText: questionText,
                printText: printText,
                footNote: footNote,
                answers: answers,
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    writeNewRecommendation = (userID, category, type, text, href) => {
        return new Promise((resolve, reject) => {
            let newKey = this.database.ref(`recommendations/${userID}/`).push().key;
            let result = this.database.ref(`recommendations/${userID}/${newKey}/`).set({
                recommendationId: newKey,
                category: category,
                type: type,
                text: text,
                href: href,
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    writeNewRecommendationsBundle = (userID, minProb, maxProb, generalRecs, redFlags, specialRecs, isActive) => {
        return new Promise((resolve, reject) => {
            let newKey = this.database.ref(`recBundles/${userID}/`).push().key;
            let result = this.database.ref(`recBundles/${userID}/${newKey}/`).set({
                bundleId: newKey,
                minProb: minProb,
                maxProb: maxProb,
                generalRecs: generalRecs,
                redFlags: redFlags,
                specialRecs: specialRecs,
                isActive: isActive,
            }, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    deleteQuestion = (userID, questionId) => {
        return new Promise((resolve, reject) => {
            const result = this.database.ref(`questions/${userID}/${questionId}/`).set(null, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    deleteRecommendation = (userID, recommendationId) => {
        return new Promise((resolve, reject) => {
            const result = this.database.ref(`recommendations/${userID}/${recommendationId}/`).set(null, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    deleteBundle = (userID, bundleId) => {
        return new Promise((resolve, reject) => {
            const result = this.database.ref(`recBundles/${userID}/${bundleId}/`).set(null, error => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result);
                }
            });
        })
    };

    loadTrees = (userID, updateTreeList) => {
        let treeDatabaseRef = this.database.ref(`decision_tree/${userID}/`);
        treeDatabaseRef.on('value', snapshot => {
            updateTreeList(snapshot.val());
        })
    }

    loadQuestions = (userID, updateQuestionList) => {
        let treeDatabaseRef = this.database.ref(`questions/${userID}/`);
        treeDatabaseRef.on('value', snapshot => {
            updateQuestionList(snapshot.val());
        })
    }

    loadRecommendations = (userID, updateRecommendationsList) => {
        let recDatabaseRef = this.database.ref(`recommendations/${userID}/`);
        recDatabaseRef.on('value', snapshot => {
            updateRecommendationsList(snapshot.val());
        })
    }

    loadBundles = (userID, updateBundlesList) => {
        let recDatabaseRef = this.database.ref(`recBundles/${userID}/`);
        recDatabaseRef.on('value', snapshot => {
            updateBundlesList(snapshot.val());
        })
    }

    loadAssets = (userID, updateAssetsList) => {

        /*return new Promise((resolve, reject) => {
            const result = this.database.ref(`assets/${userID}/`).on('value', error => {
                console.log(error)
                if (error) {
                    reject(error);
                } else {
                    resolve(updateAssetsList(result.val()));
                }
            });
        })*/
        let assetsDatabaseRef = this.database.ref(`assets/${userID}/`);
        assetsDatabaseRef.on('value', (snapshot) => {
            updateAssetsList(snapshot.val());
        })
    }

    fetchAssets = (userID, updateAssets) => {
        let assetsDatabaseRef = this.database.ref(`assets/${userID}/`);
        assetsDatabaseRef.on('value', (snapshot) => {
            updateAssets(snapshot.val());
        })
    }
}


export default Firebase;
