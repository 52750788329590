/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//project code
import { STYLES_CONSTS } from './share/variables';
import {connect} from "react-redux";
import { ExternalLink } from "./share/Link";
import { scale } from './share/utils';

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    contentContainer: {
        overflow: "auto"
    },
    content: {
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontSize: scale(15),
        fontWeight: 300,
        letterSpacing: 0,
        marginBottom: scale(20),
        textAlign: 'left',
    },
    button: theme.blueButton
});

class Privacy extends React.Component {


    render() {
        const { classes, history, assets } = this.props;

        if (!assets) history.push('/');
        if (!assets) return null;

        return (
            <div className={classes.container}>
                <Typography variant="h5" className={classes.heading}>
                    {assets.disclaimer.title}
                </Typography>
                <div className={classes.contentContainer}>
                    <Typography variant="body2" className={classes.content}>
                        This tool is intended for use by a health care professional only.

                    </Typography>
                    <Typography variant="body2" className={classes.content}>
                        This tool may augment good clinical care and is not meant to replace a clinical diagnosis,
                        assessment or treatment plan.

                    </Typography>
                    <Typography variant="body2" className={classes.content}>
                        The data used in this tool were obtained from the
                        <ExternalLink
                            linkURL='http://umanitoba.ca/faculties/health_sciences/medicine/units/chs/departmental_units/mchp/about.html'
                            text='Manitoba Centre for Health Policy;' />however, the results and conclusions are
                        those of the App contributors, and no official
                        endorsement by the Manitoba Centre for Health Policy, Manitoba Health, or other data
                        providers is intended or should be inferred.
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => history.goBack()}
                >
                    {assets.disclaimer.goBackButtonCaption}
                </Button>
            </div>
        );
    }
}

Privacy.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { assets } = state.appState;
    return { assets }
};

export default connect(mapStateToProps)(withStyles(styles)(Privacy));
