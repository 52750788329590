import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";

class ConfirmDeploymentDialog extends React.Component {

    handleConfirmed = () => {
        this.props.confirmed();
    };

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">You are about to deploy: </Typography>
                        {this.props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleConfirmed} color="primary" autoFocus>
                        Deploy
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDeploymentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    confirmed: PropTypes.func.isRequired,
    canceled: PropTypes.func.isRequired,
    content: PropTypes.object,
    open: PropTypes.bool.isRequired
};

export default ConfirmDeploymentDialog;