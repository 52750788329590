import React from "react";

const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

const height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

const guidelineBaseWidth = 414;
const guidelineBaseHeight = 732;



const scale = size => {
    if (width > guidelineBaseWidth) {
        return size;
    } else {
        return (width / guidelineBaseWidth) * size;
    }
};

const verticalScale = size => (height / guidelineBaseHeight) * size;

const getRecText = (val, category, data) => {
    let content = [];
    data.forEach(i => {
        if (parseFloat(val) >= parseFloat(i.minProb) && parseFloat(val) <= parseFloat(i.maxProb)) {
            if (i.generalRecs && category === 'general') {
                content = i.generalRecs;
            } else if (i.redFlags && category === 'redflags') {
                content = i.redFlags;
            } else if (i.specialRecs && category === 'special') {
                content = i.specialRecs;
            }
        }
    });

    return content.map(el => {
        if (el.type === 'link') {
            return <a href={el.href} target="_blank" rel="noopener noreferrer">{el.text}</a>
        }
        return el.text;
    });
};
export { scale, getRecText, verticalScale };
