import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {STYLES_CONSTS} from "./variables";



export const GeneralRecomendations = props =>
    <div className={props.styles}>
        <ol>
            <li>If a treatable cause for the symptoms has not been identified and treated, and the psychosis is persisting,
                monitor closely and reassess often.</li>
            <li>If RED FLAGS are present, strongly consider referring to specialized programs for further assessment.</li>
            <li>Confirm that the diagnosis of unspecified psychotic disorder is valid.</li>
                <ol type="a">
                    <li>Review available clinical records</li>
                    <li>If the diagnosis was made at the time of a hospitalization, was it changed during the admission?</li>
                </ol>
            <li>Consider the contribution of substances, especially cannabis and stimulants.</li>
            <ol type="a">
                <li>Establish a timeline for onset of psychosis in relation to the substance use.</li>
                <li>A period of abstinence from the substance could be helpful in clarifying the diagnosis.</li>
                <li>Target treatment concurrently if symptoms are severe and impairing.</li>
                <li>If a substance use disorder is present, offer support and help with access to resources. Services
                    listed at:
                    <Tooltip
                        title="https://www.gov.mb.ca/health/mh/addictions/adult.html"
                        placement="bottom-start"
                    >
                        <a style={{textDecoration: 'none'}} href='https://www.gov.mb.ca/health/mh/addictions/adult.html' rel='noopener noreferrer' target='_blank'>
                            https:// www.gov.mb.ca/ health/ mh/ addictions/ adult.html
                        </a>
                    </Tooltip>
                    </li>
            </ol>
            <li>Assess and treat other conditions that may be associated with the psychosis, such as mood disorders,
                anxiety, personality disorder, and medical comorbidities</li>
            <li>Offer psychosocial supports and intervention if indicated</li>
            <ol type="a">
                <li>Involve families where appropriate</li>
                <li>Offer access to social work and government resources for financial and vocational assistance</li>
                <li>Refer to Cognitive Behavioral Therapy for psychosis if available</li>
                <li>Connect to community resources</li>
                <ol type="i">
                    <li><a style={{textDecoration: 'none'}} href="https://mbwpg.cmha.ca/" rel='noopener noreferrer' target='_blank'>Canadian Mental Health Association</a></li>
                    <li><a style={{textDecoration: 'none'}} href="https://www.mss.mb.ca/" rel='noopener noreferrer' target='_blank'>Manitoba Schizophrenia Society</a></li>
                </ol>
                <li>Provide educational resources</li>
                <ol type="i">
                    <li>
                        <a style={{textDecoration: 'none'}} href="http://www.heretohelp.bc.ca/ask-us/whats-the-difference-between-psychosis-and-schizophrenia" rel='noopener noreferrer' target='_blank'>
                            British Columbia chapter of the Canadian Mental Health Association</a></li>
                    <li><a style={{textDecoration: 'none'}} href="https://www.nami.org/earlypsychosis" rel='noopener noreferrer' target='_blank'>National Alliance on Mental Illness (NAMI)</a></li>
                    <li><a style={{textDecoration: 'none'}} href="https://www.mss.mb.ca/resources.php" rel='noopener noreferrer' target='_blank'>Manitoba Schizophrenia Society</a></li>
                    <li><a style={{textDecoration: 'none'}} href="https://www.nimh.nih.gov/health/topics/schizophrenia/index.shtml" rel='noopener noreferrer' target='_blank'>National Institute of
                        Mental Health (NIMH)</a></li>
                </ol>
            </ol>
            <li>Antipsychotics are not recommended for prevention of psychotic illness but may be indicated if symptoms
                are sufficiently severe or impairing</li>
            <li>When psychotic symptoms are impairing, treat according to available treatment guidelines:</li>
            <ol type="a">
                <li><a style={{textDecoration: 'none'}} href="https://journals.sagepub.com/doi/abs/10.1177/0706743717719895" rel='noopener noreferrer' target='_blank'>Canadian Psychiatric
                    Association – clinical high risk psychosis</a></li>
                <li><a style={{textDecoration: 'none'}} href="https://journals.sagepub.com/doi/10.1177/0706743717719899" rel='noopener noreferrer' target='_blank'>Canadian Psychiatric
                    Association - schizophrenia</a></li>
                <li><a style={{textDecoration: 'none'}} href="https://www.nice.org.uk/guidance/cg178" rel='noopener noreferrer' target='_blank'>National Institute for Health
                    and Care Excellence (NICE)</a></li>
                <li><a style={{textDecoration: 'none'}} href="http://www.europsy.net/app/uploads/2013/11/2.-EPA-Guidance-on-the-early-intervention-in-clinical-high-risk-states-of-psychoses.pdf" rel='noopener noreferrer' target='_blank'>European Psychiatric Association</a></li>
            </ol>
            <li>Primary care providers should consider referral to a Psychiatrist or Early Intervention in Psychosis
                Service if unsure about treatment.</li>
            <ol type="a">
                <li>Family physicians, nurse practitioners and pediatricians can receive advice by phone from a
                    psychiatrist (adult or child/adolescent) through the Rapid Access to Consultative Expertise (RACE)
                    line: <strong>204-940-2573</strong>.</li>
                <li>Rapid electronic psychiatry consultation available through eConsult Manitoba. Contact&nbsp;
                    <span style={{color: STYLES_CONSTS.SPECIAL_BUTTON}}><strong>mbeconsult@umanitoba.ca</strong></span> to learn
                    more.</li>
                <li>For Psychiatric Consultation in Winnipeg:&nbsp;<br />
                    <Tooltip
                        title="http://www.wrha.mb.ca/professionals/familyphysicians/referral.php"
                        placement="bottom-start"
                    >
                        <a style={{textDecoration: 'none'}} href="http://www.wrha.mb.ca/professionals/familyphysicians/referral.php" rel='noopener noreferrer' target='_blank'>
                            http:// www.wrha.mb.ca/ professionals/ familyphysicians/ referral.php</a>
                    </Tooltip>
                </li>
                <li>Early Psychosis Prevention and Intervention Service (EPPIS):&nbsp;<br />
                    <Tooltip
                        title="http://www.wrha.mb.ca/prog/mentalhealth/files/EPPISFACTSheet13.pdf"
                        placement="bottom-start"
                    ><a style={{textDecoration: 'none'}} href="http://www.wrha.mb.ca/prog/mentalhealth/files/EPPISFACTSheet13.pdf" rel='noopener noreferrer' target='_blank'>
                        http:// www.wrha.mb.ca/ prog/ mentalhealth/ files/ EPPISFACTSheet13.pdf
                    </a></Tooltip></li>
            </ol>
        </ol>
    </div>;

export const RedFlags = props =>
    <div className={props.styles}>
        <ol>
            <li>Regardless of the risk estimate provided, if any of the following are present, consider referral to a
                psychiatrist or Early Intervention in Psychosis Program due to these features being associated with
                increased risk of illness progression.</li>
            <ul type="disc">
                <li>Significant distress with a decline in social functioning</li>
                <li>Recurrent brief limited intermittent or continuous attenuated psychotic symptoms (particularly if
                    only minimal insight or declining insight, or if increasing frequency of symptoms)</li>
                <li>High levels of suspiciousness or unusual thought content</li>
                <li>A first-degree relative with psychosis, schizophrenia or schizotypal personality disorder</li>
            </ul>
            <li>If a person is in crisis, or there is risk of harm to self or others provide information about resources
                and intervene as appropriate.</li>
            <ol type="a">
                <li>Province-Wide:</li>
                    <ol type="i">
                        <li>
                            <Tooltip
                                title="https://www.gov.mb.ca/health/mh/crisis.html"
                                placement="bottom-start"
                            ><a href="https://www.gov.mb.ca/health/mh/crisis.html" rel='noopener noreferrer' target='_blank'>
                                https:// www.gov.mb.ca / health / mh / crisis.html
                            </a></Tooltip>
                        </li>
                        <li>Any emergency room or 911</li>
                    </ol>
                <li>Within Winnipeg:</li>
                    <ol type="i">
                        <li>WRHA Crisis Response Centre: <strong>817 Bannatyne Avenue</strong></li>
                        <li>WRHA Mobile Crisis Services: <strong>204-940-1781</strong></li>
                        <li>WRHA Youth Mobile Crisis Services: <strong>204-949-4777 or 1-888-383-2776</strong></li>
                        <li>Youth Resource Centre/Shelter: <strong>204-477-1804</strong></li>
                    </ol>
            </ol>
        </ol>
    </div>;

    export const SpecialRecommendation = props => {
        let returnComponent = null;
        switch (props.probability) {
            case '30.4':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.</li>
                        <li>If symptoms are persisting or RED FLAGS are present, consider treatment and referral to an
                            Early Intervention in Psychosis program for further assessment (see General
                            recommendations).</li>
                    </ol>
                );
                break;
            case '46.1':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Given a high risk of illness progression, strongly consider referral of this patient to an
                            Early Intervention in Psychosis program if they meet criteria for referral.
                        </li>
                    </ol>

                );
                break;
            case '46.8':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Review hospitalization records for information that might help with clarification of
                            diagnosis and previous responses to treatment.
                        </li>
                        <li>Given a high risk of illness progression, strongly consider referral of this patient to an
                            Early Intervention in Psychosis program if they meet criteria for referral.
                        </li>
                    </ol>

                );
                break;
            case '24.7':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, strongly consider referral to a
                            psychiatrist or Early Intervention in Psychosis program for further assessment (see General
                            recommendations).
                        </li>
                    </ol>
                );
                break;
            case '26.4':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, consider treatment and referral to an
                            Early Intervention in Psychosis program for further assessment (see General recommendations).
                        </li>
                    </ol>
                );
                break;
            case '34.6':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Review hospitalization records for information that might help with clarification of
                            diagnosis and previous responses to treatment.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, consider treatment and referral to an
                            Early Intervention in Psychosis program for further assessment
                            (see General recommendations).</li>
                    </ol>
                );
                break;
            case '52.4':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Review hospitalization records for information that might help with clarification of
                            diagnosis and previous responses to treatment.
                        </li>
                        <li>A careful assessment of the personality disorder and relationship to psychosis should be
                            conducted. This can be complex. Individuals with Cluster A personality disorders
                            (schizotypal, paranoid, schizoid) can often have odd, eccentric or distorted thinking that
                            may appear psychotic. Individuals with Borderline Personality Disorder can experience brief
                            psychotic symptoms that are usually self-resolving.</li>
                        <li>Consider if the personality symptoms are better explained by an underlying psychotic
                            illness.</li>
                        <li>Individuals can have co-occurring personality and psychotic disorders and would benefit
                            from treatment for both. Treatment for the personality disorder will require that the
                            psychosis has been stabilized.</li>
                        <li>If symptoms are persisting or RED FLAGS are present, consider referral to an Early
                            Intervention in Psychosis program for further assessment (see General recommendations).</li>
                    </ol>
                );
                break;
            case '32.4':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Review hospitalization records for information that might help with clarification of
                            diagnosis and previous responses to treatment.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, consider treatment and referral to an
                            Early Intervention in Psychosis program for further assessment (see General
                            recommendations).</li>
                    </ol>
                );
                break;
            case '38.9':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Consider the possibility of a primary mood disorder underlying the psychotic symptoms.
                            Establishing a clear timeline for symptom onset in association with mood changes can be
                            helpful. In particular, depression can present with mood congruent psychotic symptoms
                            (auditory hallucinations, delusions of poverty, guilt, persecution). Mania can present
                            with both mood congruent and incongruent psychotic symptoms including grandiose delusions
                            and paranoia.
                        </li>
                        <li>If present, ensure that mood symptoms are adequately treated. Combination treatment with
                            antidepressants/mood stabilizers and antipsychotics may be required.</li>
                        <li>In rare cases, extreme anxiety can sometimes look like psychosis, particularly if there is
                            a lack of insight. Examples include illness anxiety (hypochondriasis) and Obsessive
                            Compulsive Disorder.</li>
                        <li>If unsure, consider referral to a mood and anxiety disorders specialist for further
                            assessment and recommendations.</li>
                        <li>If a mood or anxiety disorder has been ruled out, symptoms are persisting or RED FLAGS are
                            present, consider treatment and referral to an Early Intervention in Psychosis program for
                            further assessment (see General recommendations).</li>
                    </ol>
                );
                break;
            case '54.2':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Given a high risk of illness progression, strongly consider referral of this patient to an
                            Early Intervention in Psychosis program for further assessment (see General recommendations).
                        </li>
                    </ol>
                );
                break;
            case '59.1':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Given a high risk of illness progression, strongly consider referral to an Early
                            Intervention in Psychosis program for further assessment (see General recommendations).
                        </li>
                    </ol>
                );
                break;
            case '42.0':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>Given a high risk of illness progression, strongly consider referral of this patient to an
                            Early Intervention in Psychosis program for further assessment (see General recommendations).
                        </li>
                    </ol>
                );
                break;
            case '8.5':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, strongly consider referral to a
                            psychiatrist or Early Intervention in Psychosis program for further assessment (see General
                            recommendations).
                        </li>
                    </ol>
                );
                break;
            case '29.1':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, strongly consider referral to a
                            psychiatrist or Early Intervention in Psychosis program for further assessment (see General
                            recommendations).
                        </li>
                    </ol>
                );

                break;
            case '12.9':
                returnComponent = (
                    <ol>
                        <li>If symptoms resolve, monitor for relapse of psychotic symptoms. There is no clear evidence
                            based recommendation for frequency of monitoring for psychotic symptoms in stable patients.
                            Screen for symptom relapse during routine appointments.
                        </li>
                        <li>If symptoms are persisting or RED FLAGS are present, strongly consider referral to a
                            psychiatrist or Early Intervention in Psychosis program for further assessment (see General
                            recommendations).
                        </li>
                    </ol>
                );
                break;
            default:
                break;
        }

        return returnComponent;
    };
