/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//project code
import Background from "../assets/Background.png";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 0,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    height: '100vh',
  }
});


const App = props => {
  const { classes } = props;
  return (
      <div className={classes.root}>
          {props.children}
      </div>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
