import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";

class ConfirmDelitionDialog extends React.Component {
    state = {
        open: false,
    };

    handleConfirmed = () => {
        this.props.confirmed();
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.canceled();
    };

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">You are about to delete: </Typography>
                        {this.props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleConfirmed} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDelitionDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    confirmed: PropTypes.func.isRequired,
    canceled: PropTypes.func.isRequired,
    content: PropTypes.object,
    open: PropTypes.bool.isRequired
};

export default ConfirmDelitionDialog;