import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ReplayIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M16,6.66666667 L16,1.33333333 L9.33333333,8 L16,14.6666667 L16,9.33333333 C20.4133333,9.33333333 24,12.92 24,17.3333333 C24,21.7466667 20.4133333,25.3333333 16,25.3333333 C11.5866667,25.3333333 8,21.7466667 8,17.3333333 L5.33333333,17.3333333 C5.33333333,23.2266667 10.1066667,28 16,28 C21.8933333,28 26.6666667,23.2266667 26.6666667,17.3333333 C26.6666667,11.44 21.8933333,6.66666667 16,6.66666667 Z" id="path-1"/>
    </SvgIcon>
);

export const ResourcesIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M24,0 L3,0 C1.35,0 0,1.35 0,3 L0,24 C0,25.65 1.35,27 3,27 L24,27 C25.65,27 27,25.65 27,24 L27,3 C27,1.35 25.65,0 24,0 Z M21.015,9 L6,9 L6,6 L21.015,6 L21.015,9 L21.015,9 Z M21.015,15 L6,15 L6,12 L21.015,12 L21.015,15 L21.015,15 Z M16.515,21 L6,21 L6,18 L16.515,18 L16.515,21 L16.515,21 Z" id="path-1"></path>
    </SvgIcon>
);

export const PrintIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M448,192 L448,77.25 C448,68.76 444.63,60.63 438.63,54.62 L393.37,9.37 C387.37,3.37 379.23,0 370.74,0 L96,0 C78.33,0 64,14.33 64,32 L64,192 C28.65,192 0,220.65 0,256 L0,368 C0,376.84 7.16,384 16,384 L64,384 L64,480 C64,497.67 78.33,512 96,512 L416,512 C433.67,512 448,497.67 448,480 L448,384 L496,384 C504.84,384 512,376.84 512,368 L512,256 C512,220.65 483.35,192 448,192 Z" id="Path"></path>
        <polygon id="Path" fill="#FFFFFF" points="408 472 104 472 104 336 408 336"></polygon>
        <path d="M408,224 L104,224 L104,36 L332,36 L332,92.4 C332,102.787 340.5025,111.2 351,111.2 L408,111.2 L408,224 Z" id="Path" fill="#FFFFFF"></path>
        <path d="M444.5,316 C424.348958,316 408,300.098958 408,280.5 C408,260.88625 424.348958,245 444.5,245 C464.651042,245 481,260.88625 481,280.5 C481,300.098958 464.651042,316 444.5,316 Z" id="Path" fill="#FFFFFF"></path>
    </SvgIcon>
);

export const PrintIconTransparent = (props) => (
    <SvgIcon {...props}>
        <path d="M448,192 C483.35,192 512,220.65 512,256 L512,368 C512,376.84 504.84,384 496,384 L448,384 L448,480 C448,497.67 433.67,512 416,512 L96,512 C78.33,512 64,497.67 64,480 L64,384 L16,384 C7.16,384 0,376.84 0,368 L0,256 C0,220.65 28.65,192 64,192 L64,32 C64,14.33 78.33,0 96,0 L370.74,0 C379.23,0 387.37,3.37 393.37,9.37 L438.63,54.62 C444.63,60.63 448,68.76 448,77.25 L448,192 Z M408,472 L408,336 L104,336 L104,472 L408,472 Z M408,224 L408,111.2 L351,111.2 C340.5025,111.2 332,102.787 332,92.4 L332,36 L104,36 L104,224 L408,224 Z M444.5,316 C464.651042,316 481,300.098958 481,280.5 C481,260.88625 464.651042,245 444.5,245 C424.348958,245 408,260.88625 408,280.5 C408,300.098958 424.348958,316 444.5,316 Z" id="Combined-Shape"></path>
    </SvgIcon>
);

export const BackArrowIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
    </SvgIcon>
);

export const ReviewIcon = (props) => (
    <SvgIcon {...props}>
        <path style={{fillRule: 'evenodd', clipRule:'evenodd'}} d="M244.186,214.604l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76
		c10.7-16.231,16.945-35.66,16.945-56.554C205.822,46.075,159.747,0,102.911,0S0,46.075,0,102.911
		c0,56.835,46.074,102.911,102.91,102.911c20.895,0,40.323-6.245,56.554-16.945c0.269,0.301,0.47,0.64,0.759,0.929l54.38,54.38
		c8.169,8.168,21.413,8.168,29.583,0C252.354,236.017,252.354,222.773,244.186,214.604z M102.911,170.146
		c-37.134,0-67.236-30.102-67.236-67.235c0-37.134,30.103-67.236,67.236-67.236c37.132,0,67.235,30.103,67.235,67.236
		C170.146,140.044,140.043,170.146,102.911,170.146z"/>
    </SvgIcon>
);

