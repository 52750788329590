/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
//project code
import { resetApp } from "../store/actions";
import { STYLES_CONSTS } from './share/variables';
import {scale} from "./share/utils";

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    button: theme.blueButton,
    summaryButton: {
        backgroundColor: STYLES_CONSTS.BLUE_BUTTON,
        minHeight: 50,
        marginTop: 16,
        width: '100%',
        '&:hover': {
            backgroundColor: STYLES_CONSTS.GREEN_BUTTON,
        }
    },
    summaryButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: 240,
        marginLeft: 10,
        paddingLeft: 10,
        fontSize: 15,
        minHeight: 20,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.WHITE_COLOR,
    },
    buttonsContainer: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    answers: {
        marginTop: 16,
        marginBottom: scale(18),
        flexGrow: 0,
        overflow: 'hidden'
    },
    iconSmall: {
        position: 'absolute',
        left: 0,
        fontSize: 20,
        width: 32,
        height: 32,
        marginRight: 30,
        marginLeft: 31
    },
    printIcon: {
        left: 0,
        fontSize: 20,
        width: 22,
        height: 22,
        marginRight: 6,
        color: STYLES_CONSTS.BLUE_BUTTON,
    },
    printButton: {
        backgroundColor: 'transparent',
        minHeight: 30,
        marginTop: 27
    },
    printButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: 240,
        marginLeft: 10,
        fontSize: 15,
        minHeight: 20,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.BLUE_BUTTON,
    },
    question: {
        marginBottom: 2,
        fontWeight: 300,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.HEADING_COLOR,
    },
    answer: {
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.HEADING_COLOR,
    },
    qaPair: {
        marginBottom: 16,
        textAlign: 'left'
    },
});


const Review = props => {
    const { classes, probability, assets, history, report } = props;


    if (!probability) history.push('/');
    if (!assets) {
        return null;
    }
    return (
        <div className={classes.container}>
            <Typography variant="h5" className={classes.heading}>
                Review Your Answers
            </Typography>
            <div className={classes.answers}>
                {report && Object.keys(report).map((el, i) =>
                    <div key={i} className={classes.qaPair}>
                        <div className={classes.question}>
                            {report[`${el}`].printText}
                        </div>
                        <div className={classes.answer}>
                            {report[`${el}`].text}
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.buttonsContainer}>
                <Button
                    classes={{root: classes.button}}
                    onClick={() => history.goBack()}
                >{assets.resources.goBackButtonCaption}</Button>
            </div>
        </div>
    );
};

Review.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { probability, assets, report } = state.appState;
    return { probability, assets, report }
};


export default connect(mapStateToProps, { resetApp })(withStyles(styles)(Review));
