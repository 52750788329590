import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {closeCreateRecommendationDialog, createNewRecommendation} from "../../store/actions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Formik, Form} from "formik";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});


class CreateRecommendationDialog extends React.Component {

    isNotEmpty = (a, v) => {
        return a !== '' && v !== '';
    }

    render() {
        const { isCreateRecommendationDialogOpen, closeCreateRecommendationDialog, firebase, classes, user } = this.props;
        return (
            <Dialog
                open={isCreateRecommendationDialogOpen}
                onClose={closeCreateRecommendationDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Create a Recommendation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a new Recommendation select it's category and type, fill in required fields
                    </DialogContentText>
                    <Formik
                        initialValues={{category: 'general', type: 'text', text: '', href: ''}}
                        validate={values => {
                            let errors = {};
                            if (!values.text) {
                                errors.text = 'Required';
                            }
                            if (!values.type) {
                                errors.type = 'Required';
                            }

                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            //values.answers.shift();
                            firebase
                                .writeNewRecommendation(user, values.category, values.type, values.text, values.href)
                                .then(rec => {
                                    setSubmitting(false);
                                    closeCreateRecommendationDialog();
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({text: true})
                                });
                        }}

                        render = {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<Form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl component="fieldset" required fullWidth>
                                        <FormLabel component="legend">Select Recommendation Category</FormLabel>
                                        <RadioGroup
                                            aria-label="position"
                                            name="category"
                                            id="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="general"
                                                control={<Radio color="primary" />}
                                                label="General"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="redflag"
                                                control={<Radio color="primary" />}
                                                label="Red Flag"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="special"
                                                control={<Radio color="primary" />}
                                                label="Special"
                                                labelPlacement="top"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl component="fieldset" required fullWidth>
                                        <FormLabel component="legend">Select Recommendation Type</FormLabel>
                                        <RadioGroup
                                            aria-label="position"
                                            name="type"
                                            id="type"
                                            value={values.type}
                                            onChange={handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="text"
                                                control={<Radio color="primary" />}
                                                label="Text"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="link"
                                                control={<Radio color="primary" />}
                                                label="Link"
                                                labelPlacement="top"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="text">Text Content</InputLabel>
                                        <Input
                                            id="text"
                                            name="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.text}
                                        />
                                    </FormControl>
                                    {values.type === 'link' &&
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="href">Link Address (https://sample.com)</InputLabel>
                                        <Input
                                            id="href"
                                            name="href"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.href}
                                        />
                                    </FormControl>
                                    }

                                    {errors.text && touched.text && errors.text &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Question Text Field is required.
                                    </Typography>
                                    }

                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Create
                                    </Button>
                                </Form>
                            )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCreateRecommendationDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

CreateRecommendationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {isCreateRecommendationDialogOpen} = state.admin;
    const {user} = state.user;
    return {isCreateRecommendationDialogOpen, user}
}


export default connect(mapStateToProps, {
    closeCreateRecommendationDialog,
    createNewRecommendation})(withStyles(styles)(CreateRecommendationDialog));