import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import { closeEditRecommendationsBundleDialog } from "../../store/actions";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import {Formik, Form, FieldArray} from "formik";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});


class EditRecommendationsBundleDialog extends React.Component {

    state = {
        genRecSelected: 'none',
        redFlagSelected: 'none',
        specRecSelected: 'none',
        generalRecs: [],
        redFlags: [],
        specialRecs: []
    };

    componentDidMount() {
        this.props.firebase.loadRecommendations(this.props.user, this.updateList)
    }

    updateList = newList => {
        const newGeneralRecs = [];
        const newRedFlagsRecs = [];
        const newSpecRecs = [];

        if (newList) {
            Object.keys(newList).forEach(i => {
                if (i) {
                    if (newList[i].category === 'general') {
                        newGeneralRecs.push(newList[i]);
                    } else if (newList[i].category === 'redflag') {
                        newRedFlagsRecs.push(newList[i]);
                    } else if (newList[i].category === 'special') {
                        newSpecRecs.push(newList[i]);
                    }
                }
            });
        }
        this.setState({
            generalRecs: newGeneralRecs,
            redFlags: newRedFlagsRecs,
            specialRecs: newSpecRecs
        });
    };

    handleSelectGeneralRecChange = event => {
        this.setState({genRecSelected: event.target.value});
    }

    handleSelectRedFlagChange = event => {
        this.setState({redFlagSelected: event.target.value});
    }

    handleSelectSpecialRecChange = event => {
        this.setState({specRecSelected: event.target.value});
    }

    isNotEmpty = (a, v) => {
        return a !== '' && v !== '';
    }

    render() {
        const { isEditRecommendationsBundleDialogOpen, closeEditRecommendationsBundleDialog, editedBundle, firebase, classes, user } = this.props;
        if (!editedBundle) return null;
        return (
            <Dialog
                open={isEditRecommendationsBundleDialogOpen}
                onClose={closeEditRecommendationsBundleDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Update Recommendations Bundle</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify Recommendations Bundle
                    </DialogContentText>
                    <Formik
                        initialValues={{minProb: editedBundle.minProb, maxProb: editedBundle.maxProb, generalRecs: editedBundle.generalRecs, redFlags: editedBundle.redFlags, specialRecs: editedBundle.specialRecs, isActive: editedBundle.isActive}}
                        validate={values => {
                            let errors = {};
                            if (!values.minProb) {
                                errors.minProb = 'Required';
                            }
                            if (parseFloat(values.minProb) > parseFloat(values.maxProb)) {
                                errors.minProbVal = 'minimum probability value must be less than maximum probability value';
                            }
                            if (!values.maxProb) {
                                errors.maxProb = 'Required';
                            }
                            if (values.generalRecs.length === 0 &&
                                values.redFlags.length === 0 &&
                                values.specialRecs.length === 0
                            ) {
                                errors.recs = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            //values.answers.shift();
                            firebase
                                .updateRecommendationsBundle(user, editedBundle.id, values.minProb, values.maxProb, values.generalRecs, values.redFlags, values.specialRecs, values.isActive)
                                .then(question => {
                                    setSubmitting(false);
                                    closeEditRecommendationsBundleDialog();
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({text: true})
                                });
                        }}

                        render = {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<Form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="text">Minimal Probability</InputLabel>
                                        <Input
                                            id="minProb"
                                            name="minProb"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.minProb}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="text">Maximum Probability</InputLabel>
                                        <Input
                                            id="maxProb"
                                            name="maxProb"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.maxProb}
                                        />
                                    </FormControl>
                                    <Typography>
                                        General Recommendations
                                    </Typography>
                                    <FieldArray
                                        name="generalRecs"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.generalRecs && values.generalRecs.length > 0 ?
                                                    values.generalRecs.map((recs, index) => (
                                                        <div key={index}>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`generalRecs.${index}.text`}>Recommendations Text {recs.id}</InputLabel>
                                                                <Input
                                                                    id={`generalRecs.${index}.text`}
                                                                    name={`generalRecs.${index}.text`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.generalRecs[index].text}
                                                                />
                                                            </FormControl>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }} // remove a friend from the list
                                                            >
                                                                Remove Recommendation
                                                            </Button>
                                                        </div>
                                                    )) : (
                                                        <div className={classes.alert}> No Recommendations Added</div>
                                                    )
                                                }
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        label="Available General Recommendations"
                                                        value={this.state.genRecSelected}
                                                        onChange={this.handleSelectGeneralRecChange}
                                                        inputProps={{
                                                            name: 'availableGeneralRecommendations',
                                                            id: 'availableGeneralRecommendations',
                                                        }}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.generalRecs.map( el =>
                                                            <MenuItem key={el.recommendationId} value={el.recommendationId}>{el.text}<small>{el.recommendationId}</small></MenuItem>
                                                        )}
                                                    </TextField>
                                                </FormControl>

                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.genRecSelected === 'none'}
                                                    onClick={() => {
                                                        arrayHelpers.insert(0, this.state.generalRecs.filter(el => el.recommendationId === this.state.genRecSelected)[0]);
                                                        this.setState({genRecSelected: 'none'})
                                                    }} // insert an empty string at a position
                                                >
                                                    Add General Recommendation
                                                </Button>

                                            </div>
                                        )}
                                    />
                                    <Typography>
                                        Red Flags
                                    </Typography>
                                    <FieldArray
                                        name="redFlags"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.redFlags && values.redFlags.length > 0 ?
                                                    values.redFlags.map((recs, index) => (
                                                        <div key={index}>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`redFlags.${index}.text`}>Recommendations Text {recs.id}</InputLabel>
                                                                <Input
                                                                    id={`redFlags.${index}.text`}
                                                                    name={`redFlags.${index}.text`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.redFlags[index].text}
                                                                />
                                                            </FormControl>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }} // remove a friend from the list
                                                            >
                                                                Remove Recommendation
                                                            </Button>
                                                        </div>
                                                    )) : (
                                                        <div className={classes.alert}> No Recommendations Added</div>
                                                    )
                                                }
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        label="Available Red Flags Recommendations"
                                                        value={this.state.redFlagSelected}
                                                        onChange={this.handleSelectRedFlagChange}
                                                        inputProps={{
                                                            name: 'availableRedFlagsRecommendations',
                                                            id: 'availableRedFlagRecommendations',
                                                        }}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.redFlags.map( el =>
                                                            <MenuItem key={el.recommendationId} value={el.recommendationId}>{el.text}<small>{el.recommendationId}</small></MenuItem>
                                                        )}
                                                    </TextField>
                                                </FormControl>

                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.redFlagSelected === 'none'}
                                                    onClick={() => {
                                                        arrayHelpers.insert(0, this.state.redFlags.filter(el => el.recommendationId === this.state.redFlagSelected)[0]);
                                                        this.setState({redFlagSelected: 'none'})
                                                    }} // insert an empty string at a position
                                                >
                                                    Add Red Flag Recommendation
                                                </Button>

                                            </div>
                                        )}
                                    />
                                    <Typography>
                                        Special Recommendations
                                    </Typography>
                                    <FieldArray
                                        name="specialRecs"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.specialRecs && values.specialRecs.length > 0 ?
                                                    values.specialRecs.map((recs, index) => (
                                                        <div key={index}>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`specialRecs.${index}.text`}>Recommendations Text {recs.id}</InputLabel>
                                                                <Input
                                                                    id={`specialRecs.${index}.text`}
                                                                    name={`specialRecs.${index}.text`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.specialRecs[index].text}
                                                                />
                                                            </FormControl>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }} // remove a friend from the list
                                                            >
                                                                Remove Special Recommendation
                                                            </Button>
                                                        </div>
                                                    )) : (
                                                        <div className={classes.alert}> No Recommendations Added</div>
                                                    )
                                                }
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        label="Available Special Recommendations"
                                                        value={this.state.specRecSelected}
                                                        onChange={this.handleSelectSpecialRecChange}
                                                        inputProps={{
                                                            name: 'availableSpecialRecommendations',
                                                            id: 'availableSpecialRecommendations',
                                                        }}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.specialRecs.map( el =>
                                                            <MenuItem key={el.recommendationId} value={el.recommendationId}>{el.text}<small>{el.recommendationId}</small></MenuItem>
                                                        )}
                                                    </TextField>
                                                </FormControl>

                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.specRecSelected === 'none'}
                                                    onClick={() => {
                                                        arrayHelpers.insert(0, this.state.specialRecs.filter(el => el.recommendationId === this.state.specRecSelected)[0]);
                                                        this.setState({specRecSelected: 'none'})
                                                    }} // insert an empty string at a position
                                                >
                                                    Add Special Recommendation
                                                </Button>

                                            </div>
                                        )}
                                    />
                                    {errors.maxProb && touched.maxProb && errors.maxProb &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        The maximum probability is required.
                                    </Typography>
                                    }
                                    {errors.minProb && touched.minProb && errors.minProb &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        The minimal probability is required.
                                    </Typography>
                                    }
                                    {errors.minProbVal && touched.minProb &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        {errors.minProbVal}
                                    </Typography>
                                    }
                                    {errors.recs &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Add At Least one recommendation.
                                    </Typography>
                                    }
                                    <FormControl margin="normal" required fullWidth>
                                        <FormControlLabel
                                            FormControlLabel
                                            control={
                                        <Checkbox
                                            name="isActive"
                                            id="isActive"
                                            checked={values.isActive}
                                            onChange={handleChange}
                                            value="isActive"
                                        />}
                                            label="is Active"
                                        />

                                    </FormControl>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save
                                    </Button>
                                </Form>
                            )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditRecommendationsBundleDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditRecommendationsBundleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {isEditRecommendationsBundleDialogOpen, editedBundle} = state.admin;
    const {user} = state.user;
    return {isEditRecommendationsBundleDialogOpen, editedBundle, user}
}


export default connect(mapStateToProps, {
    closeEditRecommendationsBundleDialog,
    })(withStyles(styles)(EditRecommendationsBundleDialog));