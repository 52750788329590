// ACTIONS
import {actionTypes} from "./store";
//appState
export const setDecisionTree = (tree) => ({
        type: actionTypes.SET_TREE,
        payload: tree
    });
export const setRecommendations = (recs) => ({
        type: actionTypes.SET_RECOMMENDATIONS,
        payload: recs
    });

export const setAssets = (assets) => ({
        type: actionTypes.SET_ASSETS,
        payload: assets
    });

export const upsertReport = (probability, answer) => ({
        type: actionTypes.UPSERT_REPORT,
        payload: {
            probability,
            answer
        }
    });

export const setInaccurateWarning = () => {
    return { type: actionTypes.INACCURATE }
};

export const resetApp =() => {
    return { type: actionTypes.RESET }
};

export const acceptTerms = () =>
     ({ type: actionTypes.ACCEPT_TERMS });

export const authSuccess = (user) => ({
        type: actionTypes.AUTHENTICATION_SUCCESS,
        payload: user
    });

//admin


export const openCreateTreeDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_TREE_DIALOG });

export const openSwapAssetsDialog = () =>
    ({ type: actionTypes.OPEN_SWAP_ASSETS_DIALOG });

export const openEditTreeDialog = tree =>
    ({
        type: actionTypes.OPEN_EDIT_TREE_DIALOG,
        payload: tree
    });

export const openEditQuestionDialog = question =>
    ({
        type: actionTypes.OPEN_EDIT_QUESTION_DIALOG,
        payload: question
    });

export const openEditRecommendationDialog = recommendation =>
    ({
        type: actionTypes.OPEN_EDIT_RECOMMENDATION_DIALOG,
        payload: recommendation
    });

export const openEditRecommendationsBundleDialog = bundle =>
    ({
        type: actionTypes.OPEN_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG,
        payload: bundle
    });

export const openEditAssetDialog = asset =>
    ({
        type: actionTypes.OPEN_EDIT_ASSET_DIALOG,
        payload: asset
    });

export const closeEditAssetDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_ASSET_DIALOG });

export const closeSwapAssetsDialog = () =>
    ({ type: actionTypes.CLOSE_SWAP_ASSETS_DIALOG });

export const openCreateQuestionDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_QUESTION_DIALOG });

export const openCreateRecommendationDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_RECOMMENDATION_DIALOG });

export const openCreateRecommendationsBundleDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG });

export const closeCreateTreeDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_TREE_DIALOG });

export const closeEditTreeDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_TREE_DIALOG });

export const closeEditQuestionDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_QUESTION_DIALOG });

export const closeEditRecommendationDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_RECOMMENDATION_DIALOG });

export const closeEditRecommendationsBundleDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG });

export const closeCreateQuestionDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_QUESTION_DIALOG });

export const closeCreateRecommendationDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_RECOMMENDATION_DIALOG });

export const closeCreateRecommendationsBundleDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG });

export const createNewTree = () =>
    ({ type: actionTypes.CREATE_NEW_TREE });

export const createNewQuestion = () =>
    ({ type: actionTypes.CREATE_NEW_QUESTION });

export const createNewRecommendation = () =>
    ({ type: actionTypes.CREATE_NEW_RECOMMENDATION });

export const tabsChanged = (event, val) =>
    ({ type: actionTypes.TABS_CHANDED, payload: val });
