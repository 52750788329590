import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import {connect} from "react-redux";
import {
    openCreateTreeDialog,
    openCreateQuestionDialog,
    openCreateRecommendationDialog,
    openCreateRecommendationsBundleDialog,
    openSwapAssetsDialog
} from "../../store/actions";

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginRight: 60,
        minWidth: 300,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: 5,
        right: 0,
        margin: '0 auto',
    },

});

function AdminAppBar(props) {
    const { classes, activeTab } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        Schizophrenia Risk Calculator - Decision Tree Tool
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />
                    </div>
                    <Fab color="secondary" aria-label="Add" className={classes.fabButton} onClick={() => {
                        if (activeTab === 1) {
                            props.openCreateQuestionDialog();
                        } else if (activeTab === 0) {
                            props.openCreateTreeDialog();
                        } else if (activeTab === 2) {
                            props.openCreateRecommendationsBundleDialog();
                        } else if (activeTab === 3) {
                            props.openCreateRecommendationDialog();
                        } else {
                            props.openSwapAssetsDialog();
                        }
                    }}>
                        { activeTab !== 4 &&
                            <AddIcon/>
                        }
                        { activeTab === 4 &&
                            <SwapIcon/>
                        }
                    </Fab>
                </Toolbar>
            </AppBar>
        </div>
    );
}

AdminAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    const {isCreateTreeDialogOpen, isCreateQuestionDialogOpen, activeTab} = state.admin;
    return {isCreateTreeDialogOpen, isCreateQuestionDialogOpen, activeTab}
};


export default connect(mapStateToProps, {
    openCreateTreeDialog,
    openCreateQuestionDialog,
    openCreateRecommendationDialog,
    openCreateRecommendationsBundleDialog,
    openSwapAssetsDialog
})(withStyles(styles)(AdminAppBar));