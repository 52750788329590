import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers'

export const actionTypes = {
    SET_PROBABILITY: 'SET_PROBABILITY',
    ACCEPT_TERMS: 'ACCEPT_TERMS',
    SET_TREE: 'SET_TREE',
    SET_RECOMMENDATIONS: 'SET_RECOMMENDATIONS',
    SET_ASSETS: 'SET_ASSETS',
    RESET: 'RESET',
    AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
    AUTHENTICATION_FAIL: 'AUTHENTICATION_FAIL',
    LOGGED_OUT: 'LOGGED_OUT',
    OPEN_CREATE_TREE_DIALOG: 'OPEN_CREATE_TREE_DIALOG',
    OPEN_EDIT_ASSET_DIALOG: 'OPEN_EDIT_ASSET_DIALOG',
    OPEN_SWAP_ASSETS_DIALOG: 'OPEN_CREATE_SWAP_ASSETS_DIALOG',
    CLOSE_SWAP_ASSETS_DIALOG: 'CLOSE_SWAP_ASSETS_DIALOG',
    CLOSE_CREATE_TREE_DIALOG: 'CLOSE_CREATE_TREE_DIALOG',
    CLOSE_EDIT_ASSET_DIALOG: 'CLOSE_EDIT_ASSET_DIALOG',
    CLOSE_EDIT_TREE_DIALOG: 'CLOSE_EDIT_TREE_DIALOG',
    OPEN_EDIT_TREE_DIALOG: 'OPEN_EDIT_TREE_DIALOG',
    OPEN_CREATE_QUESTION_DIALOG: 'OPEN_CREATE_QUESTION_DIALOG',
    OPEN_CREATE_RECOMMENDATION_DIALOG: 'OPEN_CREATE_RECOMMENDATION_DIALOG',
    OPEN_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG: 'OPEN_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG',
    CLOSE_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG: 'CLOSE_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG',
    OPEN_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG: 'OPEN_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG',
    CLOSE_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG: 'CLOSE_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG',
    OPEN_EDIT_QUESTION_DIALOG: 'OPEN_EDIT_QUESTION_DIALOG',
    CLOSE_EDIT_QUESTION_DIALOG: 'CLOSE_EDIT_QUESTION_DIALOG',
    CLOSE_CREATE_QUESTION_DIALOG: 'CLOSE_CREATE_QUESTION_DIALOG',
    CLOSE_CREATE_RECOMMENDATION_DIALOG: 'CLOSE_CREATE_RECOMMENDATION_DIALOG',
    CREATE_NEW_TREE: 'CREATE_NEW_TREE',
    CREATE_NEW_QUESTION: 'CREATE_NEW_QUESTION',
    CREATE_NEW_RECOMMENDATION: 'CREATE_NEW_RECOMMENDATION',
    OPEN_EDIT_RECOMMENDATION_DIALOG: 'OPEN_EDIT_RECOMMENDATION_DIALOG',
    CLOSE_EDIT_RECOMMENDATION_DIALOG: 'CLOSE_EDIT_RECOMMENDATION_DIALOG',
    TABS_CHANDED: 'TABS_CHANDED',
    INACCURATE: 'INACCURATE',
    UPSERT_REPORT: 'UPSERT_REPORT'
};

export const history = createBrowserHistory();

export function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunkMiddleware
            ),
        ),
    )

    return store
}
