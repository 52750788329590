import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {closeEditAssetDialog} from "../../store/actions";
import FormControl from '@material-ui/core/FormControl';
import {Formik, Form} from "formik";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});


class EditAssetDialog extends React.Component {

    render() {
        const { isEditAssetDialogOpen, editedAsset, closeEditAssetDialog, firebase, classes, user } = this.props;
        if (!editedAsset) return null;

        return (
            <Dialog
                open={isEditAssetDialogOpen}
                onClose={closeEditAssetDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit Asset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify a Value of {editedAsset.fieldName}
                    </DialogContentText>
                    <Formik
                        initialValues={{ text: editedAsset.dbVal }}
                        validate={values => {
                            let errors = {};
                            if (!values.text) {
                                errors.text = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            firebase
                                .updateAsset(user, editedAsset.id, values.text)
                                .then(asset => {
                                    setSubmitting(false);
                                    closeEditAssetDialog();
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({text: true})
                                });
                        }}

                        render = {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<Form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            id="text"
                                            name="text"
                                            label="Asset Text"
                                            variant="outlined"
                                            multiline
                                            rowsMax="4"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.text}
                                        />
                                    </FormControl>
                                    {errors.text && touched.text && errors.text &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Asset Text Field is required.
                                    </Typography>
                                    }

                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save Changes
                                    </Button>
                                </Form>
                            )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditAssetDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditAssetDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {isEditAssetDialogOpen, editedAsset} = state.admin;
    const {user} = state.user;
    return {isEditAssetDialogOpen, editedAsset, user}
}


export default connect(mapStateToProps, {
    closeEditAssetDialog})(withStyles(styles)(EditAssetDialog));