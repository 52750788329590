import {actionTypes} from "./store";
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";

const initialState = {
    probability: null,
    inaccurate: false,
    termsAccepted: false,
    report: null,
    tree: null,
    recommendations: null,
    assets: null
};

const initialUserState = {
    isAuthenticated: false,
    user: null
};

const initialAdminState = {
    isCreateTreeDialogOpen: false,
    isEditTreeDialogOpen: false,
    isEditQuestionDialogOpen: false,
    isCreateQuestionDialogOpen: false,
    isCreateRecommendationDialogOpen: false,
    isCreateRecommendationsBundleDialogOpen: false,
    isEditRecommendationsBundleDialogOpen: false,
    isEditRecommendationDialogOpen: false,
    isSwapAssetsDialogOpen: false,
    isEditAssetDialogOpen: false,
    editedAsset: null,
    editedTree: null,
    editedBundle: null,
    editedQuestion: null,
    editedRecommendation: null,
    activeTab: 0
};

// REDUCERS
const appState = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCEPT_TERMS:
            return Object.assign({}, state, {
                termsAccepted: true
            })
        case actionTypes.SET_TREE:
            return Object.assign({}, state, {
                tree: action.payload
            })
        case actionTypes.SET_RECOMMENDATIONS:
            return Object.assign({}, state, {
                recommendations: action.payload
            })
        case actionTypes.SET_ASSETS:
            return Object.assign({}, state, {
                assets: action.payload
            })
        case actionTypes.SET_PROBABILITY:
            return Object.assign({}, state, {
                probability: action.payload
            })
        case actionTypes.INACCURATE:
            return Object.assign({}, state, {
                inaccurate: true
            })
        case actionTypes.UPSERT_REPORT:
            const { answer } = action.payload;
            let newReport = {};
            if (state.report && state.report[`${answer.questionId}`]) {
                for (let el of Object.keys(state.report)) {

                    if (el === answer.questionId) {
                        newReport[`${el}`] = answer.answer;
                        break;
                    } else {
                        newReport[`${el}`] = state.report[`${el}`];
                    }
                }
            } else if (state.report && Object.entries(state.report).length > 0) {
                for (let el of Object.keys(state.report)) {
                    if (state.report[`${el}`].nextQuestion === answer.questionId) {
                        newReport[`${el}`] = state.report[`${el}`];
                        newReport[`${answer.questionId}`] = answer.answer;
                        break;
                    } else {
                        newReport[`${el}`] = state.report[`${el}`];
                    }
                }
            } else {
                newReport[`${answer.questionId}`] = answer.answer;
            }

            return Object.assign({}, state, {
                probability: action.payload.probability,
                report: newReport
            })
        case actionTypes.RESET:
            return Object.assign({}, state, initialState)
        default:
            return state
    }
}

const user = (state = initialUserState, action) => {
    switch (action.type) {
        case actionTypes.AUTHENTICATION_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
                user: action.payload
            })
        case actionTypes.AUTHENTICATION_FAIL:
            return Object.assign({}, state, {
                isAuthenticated: false
            })
        case actionTypes.LOGGED_OUT:
            return Object.assign({}, state, {
                isAuthenticated: false,
                user: null
            })
        default:
            return state
    }
}

const admin = (state = initialAdminState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_TREE_DIALOG:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: true
            })
        case actionTypes.CLOSE_CREATE_TREE_DIALOG:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: false
            })
        case actionTypes.OPEN_SWAP_ASSETS_DIALOG:
            return Object.assign({}, state, {
                isSwapAssetsDialogOpen: true
            })
        case actionTypes.CLOSE_SWAP_ASSETS_DIALOG:
            return Object.assign({}, state, {
                isSwapAssetsDialogOpen: false
            })
        case actionTypes.OPEN_EDIT_TREE_DIALOG:
            return Object.assign({}, state, {
                isEditTreeDialogOpen: true,
                editedTree: action.payload
            })
        case actionTypes.CLOSE_EDIT_TREE_DIALOG:
            return Object.assign({}, state, {
                isEditTreeDialogOpen: false,
                editedTree: null
            })
        case actionTypes.OPEN_EDIT_ASSET_DIALOG:
            return Object.assign({}, state, {
                isEditAssetDialogOpen: true,
                editedAsset: action.payload
            })
        case actionTypes.CLOSE_EDIT_ASSET_DIALOG:
            return Object.assign({}, state, {
                isEditAssetDialogOpen: false,
                editedAsset: null
            })
        case actionTypes.OPEN_EDIT_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isEditQuestionDialogOpen: true,
                editedQuestion: action.payload
            })
        case actionTypes.CLOSE_EDIT_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isEditQuestionDialogOpen: false,
                editedQuestion: null
            })
        case actionTypes.OPEN_CREATE_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: true
            })
        case actionTypes.CLOSE_CREATE_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: false
            })
        case actionTypes.CLOSE_CREATE_RECOMMENDATION_DIALOG:
            return Object.assign({}, state, {
                isCreateRecommendationDialogOpen: false
            })
        case actionTypes.CLOSE_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG:
            return Object.assign({}, state, {
                isCreateRecommendationsBundleDialogOpen: false
            })
        case actionTypes.OPEN_CREATE_RECOMMENDATIONS_BUNDLE_DIALOG:
            return Object.assign({}, state, {
                isCreateRecommendationsBundleDialogOpen: true
            })
        case actionTypes.CREATE_NEW_TREE:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: false,
            })
        case actionTypes.OPEN_CREATE_RECOMMENDATION_DIALOG:
            return Object.assign({}, state, {
                isCreateRecommendationDialogOpen: true,
            })
        case actionTypes.OPEN_EDIT_RECOMMENDATION_DIALOG:
            return Object.assign({}, state, {
                isEditRecommendationDialogOpen: true,
                editedRecommendation: action.payload
            })
        case actionTypes.CLOSE_EDIT_RECOMMENDATION_DIALOG:
            return Object.assign({}, state, {
                isEditRecommendationDialogOpen: false,
                editedRecommendation: null
            })
        case actionTypes.OPEN_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG:
            return Object.assign({}, state, {
                isEditRecommendationsBundleDialogOpen: true,
                editedBundle: action.payload
            })
        case actionTypes.CLOSE_EDIT_RECOMMENDATIONS_BUNDLE_DIALOG:
            return Object.assign({}, state, {
                isEditRecommendationsBundleDialogOpen: false,
                editedBundle: null
            })
        case actionTypes.CREATE_NEW_QUESTION:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: false,
            })
        case actionTypes.TABS_CHANDED:
            return Object.assign({}, state, {
                activeTab: action.payload,
            })
        default:
            return state
    }
}




export default (history) => combineReducers({
    appState,
    user,
    admin,
    router: connectRouter(history)
});