/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
//code
import { acceptTerms, setAssets, setDecisionTree, setRecommendations } from "../store/actions";
import {appCopy} from '../data/copy';
import { STYLES_CONSTS } from './share/variables';
import { questions } from '../data/questions';
import { recommendations } from '../data/recommendations';
import { scale } from './share/utils';
import { AppLink, ExternalLink } from './share/Link';
import { Disclaimer } from './share/Disclaimer';

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    title: {
        marginTop: scale(20),
        fontSize: scale(55),
        color: STYLES_CONSTS.INTRO_TITLE_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 300,
        letterSpacing: -1.01,
        minHeight: scale(126)
    },
    description: {
        marginTop: scale(52),
        fontSize: scale(18),
        marginBottom: scale(52),
        letterSpacing: 0,
        '& > p': {
            fontFamily: 'Open Sans',
            fontWeight: 300,
        }
    },
    button: theme.blueButton,
    doNotAgreeButton: {
        backgroundColor: STYLES_CONSTS.WHITE_COLOR
    },
    doNotAgreeButtonLabel: {
        color: STYLES_CONSTS.PREVIOUS_BUTTON,
        fontSize: 15,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0.11,
    },
    paper: {
        marginLeft: scale(24),
        marginRight: scale(24)
    },
    termsTitle: {
        color: STYLES_CONSTS.QUESTION_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontSize: scale(25),
        fontWeight: 300,
        height: scale(27),
        letterSpacing: 0.3,
        marginBottom: scale(20),
        textAlign: 'center',
        lineHeight: scale(26) + 'px'
    },
    termsText: {
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontSize: scale(15),
        fontWeight: 300,
        letterSpacing: 0,
        textAlign: 'left'
    },
    dialogContent: {
        marginTop: 27,
        marginBottom: 20
    },
    noBullets: {
        listStyleType: 'none',
        paddingInlineStart: 0,
        '& li': {
            marginBottom: 16
        }
    }
});

class Intro extends React.Component {
    state = {
        open: false,
    };

    componentDidMount() {
        //fetch an active tree
        fetch('https://schizo-calc.firebaseio.com/active_tree.json')
            .then((response) => {
                try {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                } catch (e) {
                    console.log(e)
                    return null;

                }
                return response;
            })
            .then((response) => {
                return response ? response.json() : null;
            })
            .then((loadedTree) => {
                if (loadedTree) {
                    this.props.setDecisionTree(loadedTree);
                } else {
                    this.props.setDecisionTree(questions);
                }
            });
        fetch('https://schizo-calc.firebaseio.com/active_recs.json')
            .then((response) => {
                try {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                } catch (e) {
                    console.log(e)
                    return null;

                }
                return response;
            })
            .then((response) => {
                return response ? response.json() : null;
            })
            .then((loadedRecs) => {
                if (loadedRecs) {
                    this.props.setRecommendations(loadedRecs);
                } else {
                    this.props.setRecommendations(recommendations);
                }
            });
        //fetch app assets (string values)
        fetch('https://schizo-calc.firebaseio.com/active_assets.json')
            .then((response) => {
                try {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                } catch (e) {
                    console.log(e)
                    return null;

                }
                return response;
            })
            .then((response) => {
                return response ? response.json() : null;
            })
            .then((loadedAssets) => {
                if (loadedAssets) {
                    this.props.setAssets(loadedAssets);
                } else {
                    this.props.setAssets(appCopy);
                }
            });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleAgree = () => {
        this.setState({
            open: false
        });
        this.props.acceptTerms();
        this.props.history.push('/questions/' + this.props.tree.initialQuestion)
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    render() {
        const { classes, assets } = this.props;
        const { open } = this.state;

        if (!assets) return null;

        return (
            <div className={classes.container}>
                <Dialog
                    classes={{
                        paper: classes.paper
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <DialogTitle
                        classes={{
                            root: classes.termsTitle
                        }}
                        disableTypography
                    >
                        {assets.intro.termsTitle}
                    </DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContent
                        }}
                    >
                        <div className={classes.termsText}>
                            <ul className={classes.noBullets}>
                                <li>To use this App, you must be a health care provider.</li>
                                <li>This App requires that the individual has a starting diagnosis of an unspecified
                                    psychotic disorder. In DSM-5 this refers to presentations with symptoms
                                    characteristic of a schizophrenia spectrum or other psychotic disorder but where
                                    full criteria for any disorder are not met, or there is insufficient information
                                    to make a specific diagnosis.</li>
                                <li>The risk estimates presented in this App were determined using a Classification
                                    Tree Analysis of individual level data retrieved from linked administrative
                                    databases from the
                                    <ExternalLink
                                        linkURL='http://umanitoba.ca/faculties/health_sciences/medicine/units/chs/departmental_units/mchp/about.html'
                                        text='Manitoba Centre for Health Policy.' /> The following variables
                                    were included in the model: age, sex, region of residence (urban, rural), income
                                    (high, low), prior diagnosis of unspecified psychotic disorder, type of provider
                                    making the diagnosis of unspecified psychotic disorder (psychiatrist or other
                                    physician/nurse practitioner), prior 12-month hospitalization (includes at the
                                    time the unspecified psychotic disorder was diagnosed), prior 12-month diagnosis
                                    of mood or anxiety disorder, personality disorder, substance use disorder,
                                    substance induced psychosis. Data used in the analysis were retrieved from
                                    physician claims, and hospitalization data. There may be other important risk
                                    factors for an individual that are not accounted for in this App and should be
                                    considered at the time of diagnosis.</li>
                                <li>This App is based on data from Manitoba between the years of 2007-2015. Results
                                    may differ across jurisdictions and time periods.</li>
                                <li>Depending on what data you enter the App will proceed down a particular pathway.
                                    You could be asked anywhere from 2 to 4 questions. In some cases, if you do not
                                    know the answer to a question you will be provided with the best available
                                    estimate based on the information you input up until that point.</li>
                                <li>Recommendations provided are a broad guide based on current available evidence.
                                    This App may augment good clinical care and is not meant to replace a clinical
                                    diagnosis, assessment or treatment plan.</li>
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            classes={{
                                root: classes.doNotAgreeButton,
                                label: classes.doNotAgreeButtonLabel
                            }}
                            onClick={this.handleClose}
                        >
                            {assets.intro.termsDoNotAgreeButton}
                        </Button>
                        <Button
                            variant="outlined"
                            classes={{
                                root: classes.button
                            }}
                            onClick={this.handleAgree}>
                            {assets.intro.termsAgreeButton}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="h5" className={classes.heading}>
                    {assets.intro.heading}
                </Typography>
                <Typography variant="h4" className={classes.title}>
                    {assets.intro.title}
                </Typography>
                <div className={classes.description}>
                    <Typography variant="body1" >
                        {assets.intro.description}
                    </Typography>
                    <Typography variant="subtitle1">
                        <AppLink
                            linkURL='/about'
                            text={assets.intro.aboutLinkText}
                        />
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    classes={{root: classes.button}}
                    onClick={this.handleClick}>
                    {assets.intro.buttonCaption}
                </Button>
                <Disclaimer
                    introText={assets.intro.disclaimerCaptionText}
                    text={assets.intro.disclaimerLinkText} />
            </div>
        );
    }
}

Intro.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {termsAccepted, tree, assets} = state.appState;
    return {termsAccepted, tree, assets}
};

export default connect(mapStateToProps, { acceptTerms, setDecisionTree, setAssets, setRecommendations })(withStyles(styles)(Intro));
