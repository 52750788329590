/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//project code
import { STYLES_CONSTS } from './share/variables';
import {connect} from "react-redux";
import { ExternalLink } from './share/Link';

const styles = theme => ({
    container: theme.pageContainer,
    heading: {...theme.pageHeading, ...{color: STYLES_CONSTS.QUESTION_COLOR}},
    contentContainer: {
        overflow: "auto"
    },
    content: {
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontSize: 15,
        fontWeight: 300,
        letterSpacing: 0,
        marginBottom: 20,
        textAlign: 'left',
    },
    button: theme.blueButton
});

class About extends React.Component {

    render() {
        const { classes, history, assets } = this.props;

        if (!assets) history.push('/');
        if (!assets) return null;

        return (
            <div className={classes.container}>
                <Typography variant="h5" className={classes.heading}>
                    {assets.about.title}
                </Typography>
                <div className={classes.contentContainer}>
                    <Typography variant="body2" className={classes.content}>
                        This App was designed to assist health care providers with risk assessment and treatment
                        planning. The App provides a risk estimate for a future diagnosis of schizophrenia when an
                        individual has received a diagnosis of unspecified psychotic disorder.
                        The data used in this App were retrieved from the
                        <ExternalLink
                            linkURL='http://umanitoba.ca/faculties/health_sciences/medicine/units/chs/departmental_units/mchp/about.html'
                            text='Manitoba Centre for Health Policy,' />a
                        repository of linked administrative databases containing a large array of data on the residents
                        of Manitoba. In addition to providing a risk estimate for a future diagnosis of schizophrenia
                        based on a number of specified criteria, the App also provides some clinical recommendations
                        for the user. The App is intended to be used by health care providers only. The risks provided
                        are only estimates and are intended to augment good clinical care. Use of the App should not
                        replace a good clinical assessment and multi-disciplinary treatment plan.
                    </Typography>
                    <Typography variant="subtitle1">
                        App Contributors:
                    </Typography>
                    <Typography variant="body2" className={classes.content}>
                        All contributors are from the Department of Psychiatry at the University of Manitoba.
                    </Typography>
                    <ul className={classes.content}>
                        <li>Dr. Jennifer Hensel MD MSc FRCPC, Psychiatrist and Medical Director of Adult Telemental Health</li>
                        <li>Dr. James Bolton MD FRCPC, Psychiatrist and Director of Research</li>
                        <li>Dr. Amir Shamlou MD FRCPC, Psychiatrist with the Early Psychosis Prevention and Intervention Service</li>
                        <li>Dr. Nina Kuzenko MD FRCPC, Psychiatrist and Medical Director of the Schizophrenia Treatment and Education Program</li>
                        <li>Dr. Cara Katz MD, Psychiatry resident</li>
                    </ul>
                    <Typography variant="body2" className={classes.content}>
                        The full published study is available:&nbsp;
                        <strong>
                            <ExternalLink
                                linkURL='https://doi.org/10.1016/j.jpsychires.2019.04.019'
                                text='Hensel JM, Chartier MJ, Ekuma O, MacWilliam L, Mota N, Tachere RO, McDougall C,
                                Bolton JM. (2019) Risk and associated factors for a future schizophrenia diagnosis after
                                an index diagnosis of unspecified psychotic disorder: a population-based study.
                                Journal of Psychiatric Research.' />
                        </strong>
                    </Typography>
                    <Typography variant="body2" className={classes.content}>
                        Funding for this project courtesy of the University of Manitoba Department of Psychiatry Frank J. Clancy Memorial Research Fund.
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => history.goBack()}
                >
                    {assets.about.goBackButtonCaption}
                </Button>
            </div>
        );
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { assets } = state.appState;
    return { assets }
};

export default connect(mapStateToProps)(withStyles(styles)(About));
