import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";
import {connect} from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) =>
     (
        <Route
            {...rest}
            render={props =>
                rest.user.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );

const mapStateToProps = state => ({user: state.user});

export default connect(mapStateToProps)(ProtectedRoute);