export const appCopy = {
    about: {
        content: "About App Content: Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc",
        goBackButtonCaption: "Go Back",
        title: "About the App"
    },
    disclaimer: {
        content: "This tool is intended for use by a health care professional only.<br /> This tool may augment good clinical care and is not meant to replace a clinical diagnosis, assessment or treatment plan. \n The data used in this tool were obtained from the Manitoba Centre for Health Policy; however, the results and conclusions are those of the App contributors, and no official endorsement by the Manitoba Centre for Health Policy, Manitoba Health, or other data providers is intended or should be inferred. ",
        goBackButtonCaption: "Go Back",
        title: "Disclaimer & Policies"
    },
    intro: {
        aboutLinkText: "Read more about the App.",
        buttonCaption: "Continue",
        description: "This tool will estimate the risk of progression to a future diagnosis of schizophrenia after a diagnosis of an unspecified psychotic disorder. At the end you can view your selections, the risk estimate, and clinical recommendations.",
        disclaimerCaptionText: "Disclaimer: This tool is not meant to replace assessment or diagnosis by a trained healthcare professional.",
        disclaimerLinkText: "View details",
        heading: "Intro & Onboarding",
        terms: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,",
        termsAgreeButton: "I agree",
        termsDoNotAgreeButton: "I do not agree",
        termsTitle: "Terms of Use",
        title: "Schizophrenia Risk Estimator"
    },
    question: {
        continuesButtonCaption: "Continue",
        heading: "Assessment",
        notSureButton: "I do not know",
        previousButtonCaption: "Previous",
        prompt: "Select An Answer To Continue"
    },
    report: {
        goBackButtonCaption: "Go Back",
        printToPdfButtonCaption: "Print to PDF"
    },
    resources: {
        genRecs: "general recommenations text	",
        generalRecsHeading: "General Recommendations",
        goBackButtonCaption: "Go Back",
        heading: "Recommendations",
        redFlags: "red flags recommendations text",
        redFlagsHeading: "Red Flags",
        specRecs: "special recommendations text	",
        specificRecommendationsHeading: "Specific Recommendations"
    },
    summary: {
        heading: "Results",
        inaccurate: "This result is reduced in potential accuracy; if you can provide more information you can get a more accurate risk estimate.",
        nextSteps: "Next Steps",
        noScoreText: "You did not provide enough information",
        printButtonCaption: "Print This Assessment",
        prompt: "This individual has a",
        resourcesButtonCaption: "View Recommendations",
        retakeButtonCaption: "Retake Assessment",
        scoreSign: "%",
        scoreText: "risk of progression to a schizophrenia diagnosis according to the information provided."
    }
}
