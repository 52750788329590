/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//project code
import { STYLES_CONSTS } from './share/variables';
import { scale } from './share/utils';

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    contentContainer: {
        overflow: "auto"
    },
    content: {
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontSize: scale(15),
        fontWeight: 300,
        letterSpacing: 0,
        marginBottom: scale(20),
        textAlign: 'center',
    },
    button: theme.blueButton
});

class NoMatch extends React.Component {


    render() {
        const { classes, history } = this.props;

        return (
            <div className={classes.container}>
                <Typography variant="h5" className={classes.heading}>
                    Sorry
                </Typography>
                <div className={classes.content}>
                    <Typography variant="body2">
                        Something went wrong on our side.
                    </Typography>
                    <Typography variant="body2">
                        Could you go to the Intro page.
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => history.push('/')}
                >
                    Go to the Intro page
                </Button>
            </div>
        );
    }
}

NoMatch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoMatch);
