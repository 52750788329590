/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
//project code
import { resetApp } from "../store/actions";
import { STYLES_CONSTS } from './share/variables';
import { scale } from './share/utils';
import { GeneralRecomendations, RedFlags, SpecialRecommendation } from './share/Recommendations';

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    button: theme.blueButton,
    generalButton: {
        backgroundColor: STYLES_CONSTS.GREEN_BUTTON,
        '&:hover': {
            backgroundColor: STYLES_CONSTS.WHITE_COLOR,
            color: STYLES_CONSTS.GREEN_BUTTON,
            borderColor: STYLES_CONSTS.GREEN_BUTTON,
        }
    },
    redFlagButton: {
        backgroundColor: STYLES_CONSTS.RED_FLAG,
        '&:hover': {
            backgroundColor: STYLES_CONSTS.WHITE_COLOR,
            color: STYLES_CONSTS.RED_FLAG,
            borderColor: STYLES_CONSTS.RED_FLAG,
        }
    },
    specialButton: {
        backgroundColor: STYLES_CONSTS.SPECIAL_BUTTON,
        '&:hover': {
            backgroundColor: STYLES_CONSTS.WHITE_COLOR,
            color: STYLES_CONSTS.SPECIAL_BUTTON,
            borderColor: STYLES_CONSTS.SPECIAL_BUTTON,
        }
    },
    specRecs: {
        flexGrow: 0,
    },
    buttonsContainer: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: scale(36)
    },
    paper: {
        marginLeft: 24,
        marginRight: 24
    },
    recommendations: {
        justifyContent: 'flex-start',
        flexGrow: 0,
        '&>*': {
            marginBottom: 16
        }
    }
});

class Resources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = (rec) => {
        switch (rec) {
            case "general":
                this.setState({
                    open: true,
                    title: this.props.assets.resources.generalRecsHeading,
                    content: <GeneralRecomendations />,
                });
                break;
            case "redFlag":
                this.setState({
                    open: true,
                    title: this.props.assets.resources.redFlagsHeading,
                    content: <RedFlags />,
                });
                break;
            case "special":
                this.setState({
                    open: true,
                    title: this.props.assets.resources.specificRecommendationsHeading,
                    content: <SpecialRecommendation probability={this.props.probability} />,
                });
                break;
            default:
                this.setState({
                    open: false,
                    title: '',
                    content: '',
                });


        }
    }

    render() {
        const { classes, history, probability, assets } = this.props;
        const { content, open, title } = this.state;

        if (!probability) history.push('/');
        if (!assets) {
            return null;
        }
        return (
            <div className={classes.container}>
                <Dialog
                    classes={{
                        paper: classes.paper
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <DialogTitle
                        classes={{
                            root: classes.termsTitle
                        }}
                        disableTypography
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContent
                        }}
                    >
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            classes={{
                                root: classes.doNotAgreeButton,
                                label: classes.doNotAgreeButtonLabel
                            }}
                            onClick={this.handleClose}
                        >
                            {assets.resources.closeButton ? assets.resources.closeButton : 'close'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="h5" className={classes.heading}>
                    {assets.resources.heading}
                </Typography>
                <div className={classes.recommendations}>
                    <div className={classes.generalRecs}>
                    <Button
                        variant="outlined"
                        fullWidth
                        classes={{root: classNames(classes.button, classes.generalButton) }}
                        onClick={() => this.handleClick('general')}>
                        {assets.resources.generalRecsHeading}
                    </Button>
                </div>
                    <div className={classes.redFlags}>
                        <Button
                            fullWidth
                            variant="outlined"
                            classes={{root: classNames(classes.button, classes.redFlagButton)}}
                            onClick={() => this.handleClick('redFlag')}>
                            {assets.resources.redFlagsHeading}
                        </Button>
                    </div>
                    <div className={classes.specRecs}>
                        <Button
                            fullWidth
                            variant="outlined"
                            classes={{root: classNames(classes.button, classes.specialButton)}}
                            onClick={() => this.handleClick('special')}>
                            {assets.resources.specificRecommendationsHeading}
                        </Button>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        classes={{root: classes.button}}
                        onClick={() => history.goBack()}
                    >{assets.resources.goBackButtonCaption}</Button>
                </div>
            </div>
        );
    }
}

Resources.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { probability, assets, recommendations } = state.appState;
    return { probability, assets, recommendations }
};


export default connect(mapStateToProps, { resetApp })(withStyles(styles)(Resources));
