/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
//project code
import { PrintIcon, ReplayIcon, ResourcesIcon, ReviewIcon  } from './share/ReplayIcon';
import { resetApp } from "../store/actions";
import { STYLES_CONSTS } from './share/variables';
import { scale } from "./share/utils";
import { Disclaimer } from "./share/Disclaimer";

const styles = theme => ({
    container: {...theme.pageContainer, ...{justifyContent: 'flex-start'}},
    heading: theme.pageHeading,
    prompt: {
        marginTop: scale(8),
        display: 'inline-block',
        fontSize: scale(STYLES_CONSTS.SECONDARY_FONT_SIZE),
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        letterSpacing: 0,
        minHeight: scale(14),
        textTransform: 'none'
    },
    score: {
        display: 'inline-block',
        fontSize: scale(46),
        color: STYLES_CONSTS.QUESTION_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 800,
        letterSpacing: 0,
        minHeight: 62,
        textTransform: 'none'
    },
    inaccurate: {
        marginTop: scale(5),
        marginBottom: scale(5),
        padding: scale(5),
        backgroundColor: STYLES_CONSTS.DISABLED_COLOR,
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'solid',
        borderColor: STYLES_CONSTS.BLUE_BUTTON
    },
    inaccurateText: {
        fontSize: scale(14),
        lineHeight: scale(14) + 'px'
    },
    noScore: {
        display: 'inline-block',
        fontSize: scale(STYLES_CONSTS.SECONDARY_FONT_SIZE),
        color: STYLES_CONSTS.NOSCORE_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 800,
        letterSpacing: 0,
        minHeight: scale(62),
        textTransform: 'none'
    },
    scoreText: {
        display: 'inline-block',
        fontSize: scale(STYLES_CONSTS.SECONDARY_FONT_SIZE),
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        letterSpacing: 0,
        minHeight: scale(62),
        textTransform: 'none',
    },
    nextSteps: {
        textAlign: 'left',
        textTransform: 'uppercase',
        marginTop: scale(19),
        marginBottom: 0,
        fontSize: scale(16),
        color: STYLES_CONSTS.NEXT_STEPS_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 500,
        letterSpacing: 0,
        minHeight: scale(22)
    },
    summaryButton: {...theme.blueButton,...{marginBottom: scale(18)}},
    summaryButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: 240,
        marginLeft: 10,
        paddingLeft: 10,
        fontSize: 15,
        minHeight: 20,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.WHITE_COLOR,
    },
    buttonsContainer: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',

    },
    answers: {
        marginBottom: 16
    },
    iconSmall: {
        position: 'absolute',
        left: 0,
        fontSize: scale(20),
        width: scale(32),
        height: scale(32),
        marginRight: scale(30),
        marginLeft: scale(32)
    },
    printIcon: {
        left: 0,
        fontSize: scale(20),
        width: scale(22),
        height: scale(22),
        marginRight: 6,
        color: STYLES_CONSTS.BLUE_BUTTON,
    },
    printButton: {
        backgroundColor: 'transparent',
        minHeight: 30,
        marginTop: 27
    },
    printButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: 240,
        marginLeft: 10,
        fontSize: 15,
        minHeight: 20,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        textTransform: 'none',
        letterSpacing: 0,
        color: STYLES_CONSTS.BLUE_BUTTON,
    },
});


const Summary = props => {
    const { classes, probability, assets, inaccurate, resetApp } = props;
    const startAgain = () => {
        resetApp();
        props.history.push('/');
    };

    if (!probability) props.history.push('/');
    if (!assets) {
        return null;
    }
    return (
        <div className={classes.container}>
            <Typography variant="h5" className={classes.heading}>
                {assets.summary.heading}
            </Typography>
            <Typography variant="h5" className={classes.prompt}>
                {assets.summary.prompt}
            </Typography>

            <div className={classes.answers}>
                <Typography className={probability === null ? classes.noScore : classes.score}>
                    {probability === null ? `${assets.summary.noScoreText}` :
                        probability + `${assets.summary.scoreSign}`}
                </Typography>

                <Typography className={classes.scoreText}>
                    {assets.summary.scoreText}
                </Typography>
                {inaccurate &&
                <div className={classes.inaccurate}>
                    <Typography variant="body1" className={classes.inaccurateText}>
                        {assets.summary.inaccurate}
                    </Typography>
                </div>
                }
            </div>
            <div className={classes.buttonsContainer}>
                <Typography variant="h5" className={classes.nextSteps}>
                    {assets.summary.nextSteps}
                </Typography>
                <Button
                        classes={{
                            root: classes.summaryButton,
                        }}
                        target="_blank"
                        onClick={() => props.history.push('/review')}
                    > <ReviewIcon
                        className={classes.iconSmall}
                        viewBox="0 0 250.313 250.313"
                    /> Review Answers</Button>
                <Button
                        classes={{
                            root: classes.summaryButton,
                        }}
                        target="_blank"
                        onClick={() => props.history.push('/resources')}
                    > <ResourcesIcon
                        className={classes.iconSmall}
                        viewBox="0 0 32 32"
                    /> {assets.summary.resourcesButtonCaption}</Button>
                <Button
                    variant="contained"
                    classes={{
                        root: classes.summaryButton,
                    }}
                    onClick={startAgain}
                > <ReplayIcon
                    className={classes.iconSmall}
                    viewBox="0 0 32 32"
                /> {assets.summary.retakeButtonCaption} </Button>
                <Button
                    classes={{
                        root: classes.printButton,
                        label: classes.printButtonLabel
                    }}
                    onClick={() => props.history.push('/report')}
                > <PrintIcon
                    className={classes.printIcon}
                    viewBox="0 0 512 512"
                /> {assets.summary.printButtonCaption}</Button>
            </div>
            <Disclaimer introText={assets.intro.disclaimerCaptionText} text={assets.intro.disclaimerLinkText} />
        </div>
    );
};

Summary.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { probability, assets, inaccurate } = state.appState;
    return { probability, assets, inaccurate }
};


export default connect(mapStateToProps, { resetApp })(withStyles(styles)(Summary));
