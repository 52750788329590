import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import { closeSwapAssetsDialog } from "../../store/actions";
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";
import {appCopy} from '../../data/copy';
import {Typography} from "@material-ui/core";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});

const compareAssets = (objLeft, objRight) => {
    let res = true;

    Object.keys(objLeft).forEach(objKey => {
        if (objKey && objRight && objRight.hasOwnProperty(objKey)) {
            if (typeof objLeft[objKey] === 'object' && typeof objRight[objKey] === 'object') {
                Object.keys(objLeft[objKey]).forEach(itemKey => {
                    if (!objRight[objKey].hasOwnProperty(itemKey)) {
                        res = false;
                    }
                });
            } else {
                res = false;
            }
        } else {
            res = false;
        }
    });

    return res;
}

class SwapAssetsDialog extends React.Component {

    state = {
        dbAssets: null
    }

    componentDidMount() {
        this.props.firebase.fetchAssets(this.props.user, this.updateAssets);
    }

    updateAssets = (assets) => this.setState({dbAssets: assets});

    deploy = () => {
        this.props.firebase.deployAssets(this.props.user, this.state.dbAssets).then( (rs) => {
            this.props.closeSwapAssetsDialog();
        }).catch( er => {
            alert('error' + er);
        })
    }

    renderValidation = () => compareAssets(appCopy, this.state.dbAssets);

    render() {
        const { isSwapAssetsDialogOpen, closeSwapAssetsDialog } = this.props;
        return (
            <Dialog
                open={isSwapAssetsDialogOpen}
                onClose={closeSwapAssetsDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Deploy Modified Assets</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To deploy modified assets, all app's field must be set in the database
                    </DialogContentText>
                    {!this.renderValidation() &&
                    <Typography> Check the Assets values in the DataBase.</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                    disabled={!this.renderValidation()}
                    variant="contained"
                    color="primary"
                    onClick={this.deploy}
                    >
                        Deploy Assets
                    </Button>
                    <Button onClick={closeSwapAssetsDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SwapAssetsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { isSwapAssetsDialogOpen } = state.admin;
    const {user} = state.user;
    return {isSwapAssetsDialogOpen, user}
}


export default connect(mapStateToProps, { closeSwapAssetsDialog })(withStyles(styles)(SwapAssetsDialog));