import Typography from '@material-ui/core/Typography';
import { AppLink } from "./Link";
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {STYLES_CONSTS} from "./variables";
import { scale } from './utils';


const styles = theme => ({
    disclaimer: {
        marginTop: scale(31),
        fontSize: scale(11),
        color: STYLES_CONSTS.DISCLAIMER_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontStyle: 'italic',
        letterSpacing: 0,
        minHeight: scale(30)
    }
});


const DisclaimerComponent = (props) => {
    const {classes, introText, text} = props;

    return (
        <Typography variant="body1" className={classes.disclaimer}>
            {introText}
            <AppLink
                linkURL='/privacy'
                text={text}
            />
        </Typography>
    );
};

DisclaimerComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Disclaimer = withStyles(styles)(DisclaimerComponent);


export {
    Disclaimer
}
