import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import Intro from './components/Intro';
import Privacy from './components/Privacy';
import About from './components/About';
import NoMatch from './components/NoMatch';
import Question from './components/Question';
import Summary from './components/Summary';
import Resources from './components/Resources';
import Report from './components/Report';
import Review from './components/Review';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history, configureStore } from './store/store';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Firebase, { FirebaseContext } from './components/Firebase';
import Dashboard from './components/AdminDashboard/Dashboard';
import Login from './components/Login';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import getPageContext from './components/share/getPageContext';

const store = configureStore()
const pageContext = getPageContext();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <FirebaseContext.Provider value={new Firebase()}>
                <MuiThemeProvider
                    theme={pageContext.theme}
                    sheetsManager={pageContext.sheetsManager}
                >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <App>
                        <Router>
                            <Switch>
                                <Route exact path="/" component={Intro} />
                                <ProtectedRoute exact path="/admin" component={Dashboard} />
                                <Route path="/login" component={Login} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/about" component={About} />
                                <Route path="/summary" component={Summary} />
                                <Route path="/report" component={Report} />
                                <Route path="/resources" component={Resources} />
                                <Route path="/review" component={Review} />
                                <Route path="/questions/:id" component={Question} />
                                <Route component={NoMatch}/>
                            </Switch>
                        </Router>
                    </App>
                </MuiThemeProvider>
            </FirebaseContext.Provider>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
