export const questions = {
    active: true,
    description: "updated Question v April 09",
    id: "-Lc1kkgzpg6q850cFGsY",
    initialQuestion: "-La13F3gpXRitdUGwElU",
    name: "Staging Tree",
    questions: [
        {
            answers: [
                {
                    answerId: "0",
                    nextQuestion: "-LYSJwx7d5rHtJ4gUCeH",
                    text: "less than 27",
                    value: "46.1"
                },
                {
                    answerId: "1",
                    nextQuestion: "-LYSJRZn21xJSl8o1Nrv",
                    text: "27 or older",
                    value: "30.4"
                }
            ],
            footNote: " ",
            printText: "Age",
            questionId: "-LYSJDWa4Dxre5xbVMmk",
            questionText: "What is the patient's age?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "none",
                    text: "No",
                    value: "26.4"
                },
                {
                    answerId: "0",
                    nextQuestion: "-LYSJmnaODrAQZzn_rVM",
                    text: "Yes",
                    value: "34.6"
                },
                {
                    answerId: 2,
                    nextQuestion: "none",
                    text: "I do not know",
                    value: "null"
                }
            ],
            footNote: "Prior 12-month hospitalization includes hospitalization at the time of the diagnosis of unspecified psychotic disorder",
            printText: "Prior 12-Month Hospitalization",
            questionId: "-LYSJRZn21xJSl8o1Nrv",
            questionText: "Has the patient been admitted to hospital for psychiatric reasons within the last 12 months?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "none",
                    text: "No",
                    value: "32.4"
                },
                {
                    answerId: "0",
                    nextQuestion: "none",
                    text: "Yes",
                    value: "52.4"
                },
                {
                    answerId: 2,
                    nextQuestion: "none",
                    text: "I do not know",
                    value: "null"
                }
            ],
            footNote: "Personality disorders include any of the Cluster A, B and C disorders in the Diagnostic and Statistical Manual of Mental Disorders",
            printText: "Personality Disorder Diagnosis in Past 12 Months",
            questionId: "-LYSJmnaODrAQZzn_rVM",
            questionText: "Has the patient been diagnosed with a personality disorder by a physician or nurse practitioner within the past 12 months?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "-LYSK93RuxYqJFHXMjUX",
                    text: "No",
                    value: "54.2"
                },
                {
                    answerId: "0",
                    nextQuestion: "none",
                    text: "Yes",
                    value: "38.9"
                },
                {
                    answerId: 2,
                    nextQuestion: "none",
                    text: "I do not know",
                    value: "null"
                }
            ],
            footNote: "Mood and anxiety disorders include all instances of mild to severe depression, bipolar disorder, adjustment disorders, and the full spectrum of anxiety disorders including obsessive compulsive, trauma-related disorders, and somatoform disorders.",
            printText: "Mood and Anxiety Diagnosis in Past 12 months",
            questionId: "-LYSJwx7d5rHtJ4gUCeH",
            questionText: "Has the patient been diagnosed with a mood or anxiety disorder by a physician or nurse practitioner within the past 12 months?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "none",
                    text: "Female",
                    value: "42.0"
                },
                {
                    answerId: "0",
                    nextQuestion: "none",
                    text: "Male",
                    value: "59.1"
                }
            ],
            footNote: " ",
            printText: "Sex",
            questionId: "-LYSK93RuxYqJFHXMjUX",
            questionText: "What is the patient's sex?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "-LYSKx06WYIPaG0dxVPM",
                    text: "less than 27",
                    value: "22.3"
                },
                {
                    answerId: "0",
                    nextQuestion: "none",
                    text: "27 or older",
                    value: "8.5"
                }
            ],
            footNote: " ",
            printText: "Age",
            questionId: "-LYSKlZYMnAHfTfrFIp1",
            questionText: "What is the patient's age?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "none",
                    text: "Female",
                    value: "12.9"
                },
                {
                    answerId: "0",
                    nextQuestion: "-LYSL9elk-PXFsJMuwix",
                    text: "Male",
                    value: "29.1"
                }
            ],
            footNote: "A test footnote text for the Second Sex question.",
            printText: "Sex",
            questionId: "-LYSKx06WYIPaG0dxVPM",
            questionText: "What is the patient's sex?"
        },
        {
            answers: [
                {
                    answerId: "1",
                    nextQuestion: "none",
                    text: "Yes",
                    value: "46.8"
                },
                {
                    answerId: "0",
                    nextQuestion: "none",
                    text: "No",
                    value: "24.7"
                },
                {
                    answerId: 2,
                    nextQuestion: "none",
                    text: "I do not know",
                    value: "null"
                }
            ],
            footNote: "Prior 12-month hospitalization includes hospitalization at the time of the diagnosis of unspecified psychotic disorder",
            printText: "Prior 12-Month Hospitalization",
            questionId: "-LYSL9elk-PXFsJMuwix",
            questionText: "Has the patient been admitted to hospital for psychiatric reasons within the last 12 months?"
        },
        {
            answers: [
                {
                    answerId: 0,
                    nextQuestion: "-LYSJDWa4Dxre5xbVMmk",
                    text: "Psychiatrist",
                    value: "38.2"
                },
                {
                    answerId: 1,
                    nextQuestion: "-LYSKlZYMnAHfTfrFIp1",
                    text: "Other Healthcare Professional",
                    value: "11.7"
                }
            ],
            footNote: "Other healthcare professionals include family physicians, nurse practitioners, and non-psychiatrist specialist medical doctors.",
            printText: "Diagnosed by",
            questionId: "-La13F3gpXRitdUGwElU",
            questionText: "The diagnosis of unspecified psychotic disorder was made by a:"
        }
    ],
    questionsNumber: 9
};