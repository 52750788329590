/* eslint-disable jsx-a11y/anchor-is-valid */
//libraries
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactGA from 'react-ga';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

//code
//import WebWorker from './WebWorker';
//import worker from '../worker/worker_file';
//import {questions} from '../data/questions';
import { STYLES_CONSTS } from './share/variables';
import { upsertReport, setInaccurateWarning } from "../store/actions";
import { scale } from './share/utils';

//Google Analytics
ReactGA.initialize('UA-133692454-1', {
    debug: false
});
ReactGA.pageview(window.location.pathname + window.location.search);

const styles = theme => ({
    container: theme.pageContainer,
    heading: theme.pageHeading,
    prompt: {
        marginTop: scale(8),
        fontSize: scale(14),
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 400,
        letterSpacing: 0,
        minHeight: scale(14),
        textTransform: 'uppercase'
    },
    question: {
        marginTop: scale(6),
        fontSize: scale(29),
        color: STYLES_CONSTS.QUESTION_COLOR,
        fontFamily: 'Open Sans Condensed',
        fontWeight: 300,
        letterSpacing: 0.35,
        minHeight: scale(90)
    },
    questionButton: theme.greenButton,
    questionButtonSel: {...theme.greenButton, ...{
        backgroundColor: STYLES_CONSTS.GREEN_BUTTON,
        color: STYLES_CONSTS.WHITE_COLOR
        }},
    topContainer: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: scale(200)
    },
    buttonsContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    answers: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: scale(16)
    },
    footNote: {
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: scale(8),
        marginBottom: scale(18),
        fontSize: scale(16),
        color: STYLES_CONSTS.TEXT_COLOR,
        fontFamily: 'Open Sans',
        fontWeight: 400,
        letterSpacing: 0,
        minHeight: scale(25),
    },
    bottomNav: {
        minHeight: scale(53),
        alignSelf: 'center',
        marginLeft: 0,
        marginRight: 0,
        width: '100%',
        backgroundColor: 'transparent'
    },
    buttonContinue: {...theme.blueButton, ...{'&:hover':'unset'}},
    buttonLabel: {...theme.blueButtonLabel, ...{'&:hover':'unset'}},
    buttonPrevious: {
        backgroundColor: 'transparent',
        minHeight: scale(53),
        '&:disabled': {
            color: STYLES_CONSTS.DISABLED_COLOR
        }
    },
    buttonPreviousLabel: {...theme.blueButtonLabel, ...{color: STYLES_CONSTS.PREVIOUS_BUTTON, '&:hover':'unset'}},
    buttonPreviousLabelDisabled: {
        color: STYLES_CONSTS.DISABLED_COLOR
    }
});

const getQuestion = (treeQuestions, path) => {
    return treeQuestions.filter(i => i.questionId === path)[0]
};

const getPredcessor = (cur, obj) => {
    let res = null;
    Object.keys(obj).forEach( el => {
        if (obj[el].nextQuestion === cur) {
            res = el;
        }
    });
    return res;
};

class Question extends Component {

    state = {
        maxCount: null,
        currentCount: 0,
        currentAnswer: null
    };

    componentDidMount() {
        /*const { match, tree } = this.props;

        this.worker = new WebWorker(worker);
        if (this.worker) {
            this.worker.postMessage({question: getQuestion(tree.questions, match.params.id), answer: null, questions: tree.questions});
        }
        this.worker.addEventListener('message', event => {
            if (this.state.maxCount === null) {
                this.setState({
                    maxCount: event.data.maxCount,
                    currentCount: event.data.maxCount
                })
            } else {
                this.setState({
                    currentCount: event.data.maxCount
                })
            }
        });*/
    }


     selectAnswer = answer => {
        const { match, tree } = this.props;
        const aQuestion = getQuestion(tree.questions, match.params.id);
        const anAnswer = {
            questionId: aQuestion.questionId,
            answer: answer.value !== 'null' ? answer : null
        };
        if (answer.value !== 'null') {
            anAnswer.answer.questionText = aQuestion.questionText;
            anAnswer.answer.printText = aQuestion.printText;
        }
        this.setState({currentAnswer: anAnswer});
     };

    returnToPrevious = () => {
        const { history, match, report } = this.props;
        const pInd = getPredcessor(match.params.id, report);
        if (typeof report[pInd] === 'object') {
            const anAnswer = {
                questionId: pInd,
                answer: report[pInd]
            };
            this.setState({currentAnswer: anAnswer });
            history.replace('/questions/' + pInd);
        }
    };

    recordAnswer = () => {
        const { currentAnswer } = this.state;
        const { history, match, upsertReport, probability, tree, setInaccurateWarning } = this.props;
        const aQuestion = getQuestion(tree.questions, match.params.id);

        if (currentAnswer && currentAnswer.answer) {
            if (currentAnswer.answer.value !== 'null') {
                const probability = currentAnswer.answer.value;
                upsertReport(probability, currentAnswer);
            }
            if (currentAnswer.answer.nextQuestion && currentAnswer.answer.nextQuestion !== 'none') {
                /*if (this.worker) {
                    this.worker.postMessage({question: aQuestion, answer: currentAnswer.answer, questions: tree.questions});
                }*/
                history.push('/questions/' + currentAnswer.answer.nextQuestion);
                this.setState({currentAnswer: null});
                ReactGA.event({
                    category: 'Question ' + aQuestion.questionText,
                    action: 'Answered',
                    label: currentAnswer.answer ? currentAnswer.answer.text : 'do not know'
                });
            } else {
                history.push('/summary');
                ReactGA.event({
                    category: 'Assessment',
                    action: 'Completed',
                    label: '' + probability
                });
            }
        } else if (currentAnswer) {
            ReactGA.event({
                category: 'Question ' + aQuestion.questionText,
                action: 'Answered',
                label: 'do not know'
            });
            ReactGA.event({
                category: 'Assessment',
                action: 'Dropped',
                label: '' + probability
            });
            setInaccurateWarning();
            history.push('/summary');
        }

    };

    isSelected = answer => {
        const { currentAnswer} = this.state;
        let result = false;

        if (currentAnswer && currentAnswer.answer) {
            result = currentAnswer.answer.answerId === answer.answerId;
        } else if (currentAnswer && currentAnswer.questionId) {
            result = answer.value === 'null';
        }
        return result;
    };

    isDontKnow = answer => {
        const { currentAnswer} = this.state;
        let result = false;
        if (currentAnswer) {
            result = currentAnswer.answer === answer;
        }
        return result;
    };

    hasPrevious = () => {
        const { report, match } = this.props;
        let result = false;
        if (report && Object.entries(report).length > 0) {
            const pInd = getPredcessor(match.params.id, report);
            if (typeof report[pInd] === 'object') {
                result = true;
            }
        }
        return result;
    };


    render() {

        const { classes, termsAccepted, history, match, tree, assets } = this.props;
        if (!termsAccepted || !tree || !match || !assets) {
            history.push('/');
        }

        if (!termsAccepted || !tree || !match || !assets) {
            return null;
        }
        const aQuestion = getQuestion(tree.questions, match.params.id);
        aQuestion.answers.sort((a,b) => {
            if (a.answerId < b.answerId) return -1;
            if (a.answerId > b.answerId) return 1;
            return 0;
        });

        return (
            <div className={classes.container}>
                <div className={classes.topContainer}>
                    <Typography variant="h5" className={classes.heading}>
                        {assets.question.heading}
                    </Typography>
                    <Typography variant="h5" className={classes.prompt}>
                        {assets.question.prompt}
                    </Typography>
                    <Typography variant="h4" className={classes.question}>
                        {aQuestion.questionText}
                    </Typography>
                </div>
                <div className={classes.buttonsContainer}>
                    <div className={classes.answers}>
                        {aQuestion.answers.map((q, i) =>
                            <Button
                                key={i}
                                variant="outlined"
                                className={this.isSelected(q) ? classes.questionButtonSel : classes.questionButton}
                                onClick={() => this.selectAnswer(q)}
                            >
                                {q.text}
                            </Button>
                        )}
                    </div>
                    <div className={classes.footNote}>
                        {aQuestion.footNote}
                    </div>
                    <BottomNavigation
                            value={null}
                            onChange={this.handleChange}
                            showLabels
                            classes={{
                                root: classes.bottomNav
                            }}
                        >
                            <BottomNavigationAction
                                label={assets.question.previousButtonCaption}
                                classes={{
                                    root: classes.buttonPrevious,
                                    label: this.hasPrevious() ? classes.buttonPreviousLabel : classes.buttonPreviousLabelDisabled
                                }}
                                disabled={!this.hasPrevious()}
                                onClick={this.returnToPrevious}
                            />
                            <BottomNavigationAction
                                label={assets.question.continuesButtonCaption}
                                variant="outlined"
                                classes={{root: classes.buttonContinue, label: classes.buttonLabel}}
                                disabled={this.state.currentAnswer === null}
                                onClick={this.recordAnswer}
                            />
                        </BottomNavigation>
                </div>
            </div>
        );
    }

}

Question.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    const {probability, termsAccepted, report, tree, assets} = state.appState;
    return {probability, termsAccepted, report, tree, assets}
};


export default connect(mapStateToProps, { upsertReport, setInaccurateWarning })(withStyles(styles)(Question));
