import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {closeEditQuestionDialog} from "../../store/actions";
import FormControl from '@material-ui/core/FormControl';
import {Formik, Form, FieldArray} from "formik";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});


class EditQuestionDialog extends React.Component {

    render() {
        const { isEditQuestionDialogOpen, editedQuestion, closeEditQuestionDialog, firebase, classes, user } = this.props;
        if (!editedQuestion) return null;
        return (
            <Dialog
                open={isEditQuestionDialogOpen}
                onClose={closeEditQuestionDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify Question text or its answers
                    </DialogContentText>
                    <Formik
                        initialValues={{
                            text: editedQuestion.text,
                            printText: editedQuestion.printText,
                            footNote: editedQuestion.footNote,
                            answers: editedQuestion.answers ? editedQuestion.answers : []}}
                        validate={values => {
                            let errors = {};
                            if (!values.text) {
                                errors.text = 'Required';
                            }
                            if (values.answers.length === 0) {
                                errors.answers = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            firebase
                                .updateQuestion(user, editedQuestion.id, values.text, values.printText, values.footNote, values.answers)
                                .then(question => {
                                    setSubmitting(false);
                                    closeEditQuestionDialog();
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({text: true})
                                });
                        }}

                        render = {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<Form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="text">Question Text</InputLabel>
                                        <Input
                                            id="text"
                                            name="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.text}
                                            autoFocus
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="printText">Printout Text</InputLabel>
                                        <Input
                                            id="printText"
                                            name="printText"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.printText}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="footNote">A footnote to the question</InputLabel>
                                        <Input
                                            id="footNote"
                                            name="footNote"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.footNote}
                                        />
                                    </FormControl>
                                    <FieldArray
                                        name="answers"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.answers && values.answers.length > 0 &&
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        //setFieldValue(`answers.${index}.answerId`, index);
                                                        arrayHelpers.insert(values.answers.length, {
                                                            text: '',
                                                            value: '',
                                                            answerId: values.answers.length,
                                                            nextQuestion: 'none'
                                                        });
                                                    }} // insert an empty string at a position
                                                >
                                                    Add More Answers
                                                </Button>
                                                }
                                                {values.answers && values.answers.length > 0 ? (
                                                    values.answers.map((answer, index) => (
                                                        <div key={index}>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`answers.${index}.text`}>Answer Text</InputLabel>
                                                                <Input
                                                                    id={`answers.${index}.text`}
                                                                    name={`answers.${index}.text`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.answers[index].text}
                                                                />
                                                            </FormControl>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`answers.${index}.value`}>Answer Value</InputLabel>
                                                                <Input
                                                                    id={`answers.${index}.value`}
                                                                    name={`answers.${index}.value`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.answers[index].value}
                                                                />
                                                            </FormControl>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`answers.${index}.answerId`}>Answer Id</InputLabel>
                                                                <Input
                                                                    id={`answers.${index}.answerId`}
                                                                    name={`answers.${index}.answerId`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.answers[index].answerId}
                                                                />
                                                            </FormControl>
                                                            { values.answers[index].hasOwnProperty('answerId') &&
                                                            <Button
                                                                size="small"
                                                                variant="text"
                                                                onClick={() => { arrayHelpers.remove(index); }} // remove a friend from the list
                                                            >
                                                                Remove
                                                            </Button>
                                                            }
                                                        </div>
                                                    ))
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => arrayHelpers.push({
                                                            text: '',
                                                            value: '',
                                                            answerId: 0,
                                                            nextQuestion: 'none'
                                                        })}>
                                                        {/* show this when user has removed all friends from the list */}
                                                        Add an Answer
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    />
                                    {errors.text && touched.text && errors.text &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Question Text Field is required.
                                    </Typography>
                                    }
                                    {errors.answers &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Add At Least two answers.
                                    </Typography>
                                    }
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save Changes
                                    </Button>
                                </Form>
                            )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditQuestionDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditQuestionDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {isEditQuestionDialogOpen, editedQuestion} = state.admin;
    const {user} = state.user;
    return {isEditQuestionDialogOpen, editedQuestion, user}
}


export default connect(mapStateToProps, {
    closeEditQuestionDialog})(withStyles(styles)(EditQuestionDialog));