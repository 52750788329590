import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AdminAppBar from './AdminAppBar';
import DecisionTreeList from './DecisionTreeList';
import QuestionList from './QuestionList';
import RecommendationsBundleList from './RecommendationsBundleList';
import RecommendationsList from './RecommendationsList';
import AssetsList from './AssetsList';
import CreateTreeDialog from './CreateTreeDialog';
import EditAssetDialog from './EditAssetDialog';
import SwapAssetsDialog from './SwapAssetsDialog';
import EditTreeDialog from './EditTreeDialog';
import EditQuestionDialog from './EditQuestionDialog';
import CreateQuestionDialog from './CreateQuestionDialog';
import CreateRecommendationDialog from './CreateRecommendationDialog';
import CreateRecommendationsBundleDialog from './CreateRecommendationsBundleDialog';
import EditRecommendationsBundleDialog from './EditRecommendationsBundleDialog';
import EditRecommendationDialog from './EditRecommendationDialog';
import {FirebaseContext} from "../Firebase";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { tabsChanged } from "../../store/actions";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const Dashboard = (props) => (
   <FirebaseContext.Consumer>
       {firebase => (
           <div>
               <CreateTreeDialog {...props} firebase={firebase} />
               <EditTreeDialog {...props} firebase={firebase} />
               <EditQuestionDialog {...props} firebase={firebase} />
               <EditRecommendationDialog {...props} firebase={firebase} />
               <CreateQuestionDialog {...props} firebase={firebase} />
               <CreateRecommendationDialog {...props} firebase={firebase} />
               <CreateRecommendationsBundleDialog {...props} firebase={firebase} />
               <EditRecommendationsBundleDialog {...props} firebase={firebase} />
               <EditAssetDialog {...props} firebase={firebase} />
               <SwapAssetsDialog {...props} firebase={firebase} />
               <AdminAppBar {...props} firebase={firebase} />
               <Tabs value={props.activeTab} onChange={props.tabsChanged}>
                   <Tab label="Decision Trees" />
                   <Tab label="Questions" />
                   <Tab label="Bundles of Recommendations" />
                   <Tab label="Recommendations" />
                   <Tab label="Assets" />
               </Tabs>
               {props.activeTab === 0 && <TabContainer>
                   <DecisionTreeList {...props} firebase={firebase}/></TabContainer>}
               {props.activeTab === 1 && <TabContainer>
                   <QuestionList {...props} firebase={firebase}/></TabContainer>}
               {props.activeTab === 2 && <TabContainer>
                   <RecommendationsBundleList {...props} firebase={firebase}/></TabContainer>}
               {props.activeTab === 3 && <TabContainer>
                   <RecommendationsList {...props} firebase={firebase}/></TabContainer>}
               {props.activeTab === 4 && <TabContainer>
                   <AssetsList {...props} firebase={firebase}/></TabContainer>}

           </div>)
       }
   </FirebaseContext.Consumer>
);

Dashboard.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const { activeTab } = state.admin;
    return {activeTab}
};


export default connect(mapStateToProps, { tabsChanged })(withStyles(styles)(Dashboard));
