import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {closeEditTreeDialog, createNewTree} from "../../store/actions";
import FormControl from '@material-ui/core/FormControl';
import { FieldArray, Form, Formik } from "formik";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from "@material-ui/core/colors/red";
import PropTypes from "prop-types";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    }
});


class EditTreeDialog extends React.Component {
    state = {
        questionSelected: 'none',
        questions: []
    };
    componentDidMount() {
        this.props.firebase.loadQuestions(this.props.user, this.updateList)
    }

    updateList = newList => {
        const newData = [];
        if (newList) {
            Object.keys(newList).forEach(i => {
                if (i) {
                    newData.push(newList[i]);
                }
            });
            this.setState({questions: newData});
        }
        this.setState({questions: newData});
    };

    handleSelectChange = event => {
        this.setState({questionSelected: event.target.value});
    }



    render() {
        const { isEditTreeDialogOpen, closeEditTreeDialog, editedTree, firebase, classes, user } = this.props;
        if (!editedTree) return null;

        return (
            <Dialog
                open={isEditTreeDialogOpen}
                onClose={closeEditTreeDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit Tree</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify Name, Description, or question list of the decision tree
                    </DialogContentText>
                    <Formik
                        initialValues={{
                            name: editedTree.name,
                            initialQuestion: editedTree.initialQuestion,
                            description: editedTree.description,
                            questions: editedTree.questions ? editedTree.questions : []
                        }}
                        validate={values => {
                            let errors = {};
                            if (!values.name) {
                                errors.name = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            firebase
                                .updateTree(user, editedTree.id, values.name, values.description, values.questions, values.initialQuestion)
                                .then(tree => {
                                    setSubmitting(false);
                                    closeEditTreeDialog();
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({name: true})
                                });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<Form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="name">Tree Name</InputLabel>
                                        <Input
                                            id="name"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            autoFocus
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="description">Description</InputLabel>
                                        <Input
                                            name="description"
                                            id="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                        />
                                    </FormControl>
                                    <div>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                select
                                                variant="outlined"
                                                label="Initial Question"
                                                value={values.initialQuestion}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: `initialQuestion`,
                                                    id: `initialQuestion`
                                                }}
                                            >
                                                <MenuItem value="none">
                                                    <em>None</em>
                                                </MenuItem>
                                                {values.questions.map( (el, iKey) =>
                                                    <MenuItem key={iKey} value={el.questionId}>{el.questionText}*{el.questionId}</MenuItem>
                                                )}
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <FieldArray
                                        name="questions"
                                        render={arrayHelpers => (
                                            <div>

                                                {values.questions && values.questions.length > 0 ?
                                                    values.questions.map((quel, index) => (
                                                        <div key={index}>

                                                            <FormControl margin="normal" required fullWidth>
                                                                <InputLabel htmlFor={`questions.${index}.questionText`}>Question Text {quel.questionId}</InputLabel>
                                                                <Input
                                                                    id={`questions.${index}.questionText`}
                                                                    name={`questions.${index}.questionText`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.questions[index].questionText}
                                                                />
                                                            </FormControl>

                                                            {quel.answers.map( (el, i) =>
                                                                <div key={i}>
                                                                    <FormControl margin="normal" required fullWidth>

                                                                        <TextField
                                                                            select
                                                                            variant="outlined"
                                                                            label={`Answer - ${i}: ${el.text} navigates To:`}
                                                                            value={values.questions[index].answers[i].nextQuestion ? values.questions[index].answers[i].nextQuestion : 'None'}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            inputProps={{
                                                                                name: `questions.${index}.answers.${i}.nextQuestion`,
                                                                                id: `questions.${index}.answers.${i}.nextQuestion`,
                                                                            }}
                                                                        >
                                                                            <MenuItem value="none">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {values.questions.map( (el, iKey) =>
                                                                            <MenuItem key={iKey} value={el.questionId}>{el.questionText}*{el.questionId}</MenuItem>
                                                                            )}
                                                                        </TextField>
                                                                    </FormControl>

                                                                </div>
                                                            )}
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }} // remove a friend from the list
                                                            >
                                                                Remove Question
                                                            </Button>
                                                        </div>
                                                    )) : (
                                                        <div className={classes.alert}> No Questions Added</div>
                                                    )
                                                }
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        label="Available Questions"
                                                        value={this.state.questionSelected}
                                                        onChange={this.handleSelectChange}
                                                        inputProps={{
                                                            name: 'availableQuestions',
                                                            id: 'availableQuestions',
                                                        }}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.questions.map( el =>
                                                            <MenuItem key={el.questionId} value={el.questionId}>{el.questionText}<small>{el.questionId}</small></MenuItem>
                                                        )}
                                                    </TextField>
                                                </FormControl>

                                                <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.state.questionSelected === 'none'}
                                                        onClick={() => {
                                                            arrayHelpers.insert(0, this.state.questions.filter(el => el.questionId === this.state.questionSelected)[0]);
                                                            this.setState({questionSelected: 'none'})
                                                        }} // insert an empty string at a position
                                                    >
                                                        Add Question
                                                    </Button>

                                            </div>
                                        )}
                                    />

                                    {errors.name && touched.name && errors.name &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Tree Name Field is required.
                                    </Typography>
                                    }
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save Changes
                                    </Button>
                                </Form>
                            )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditTreeDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditTreeDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {isEditTreeDialogOpen, editedTree} = state.admin;
    const {user} = state.user;
    return {isEditTreeDialogOpen, editedTree, user}
}


export default connect(mapStateToProps, {
    closeEditTreeDialog,
    createNewTree})(withStyles(styles)(EditTreeDialog));